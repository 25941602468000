import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Form, Tabs,Tab,Button,Card, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getIsAdmin, removeUserSession } from '../../utils/Common';

const urlCompany = "../components/companies.cfc?";
const urlUser = "../components/users.cfc?method=getUsersJSON";
const urlIndustry = "../components/industryFunctions.cfc?method=qryIndustryJSON";
const urlState = "../components/stateFunctions.cfc?method=qryStateJSON";

function CompanyProfile (props){

  const mounted = useRef();
  const [showHide, setShowHide] = useState(false);
  const [users, setUsers] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [company, setCompany] = useState(false);
  const [states, setStates] = useState(false);
  const [industries, setIndustries] = useState(false);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
      if (!mounted.current) {
        // do componentDidMount logic
        const { companyid } = props

        loadCompany(companyid);
        loadCompanyUsers(companyid);

        loadIndustries();
        loadStates();

        setIsAdmin(getIsAdmin());

        console.log("Can user edit Company Profile? " + getIsAdmin())

        mounted.current = true;
      } else {
        // do componentDidUpdate logic

      }
    }, []);

  const handleSubmit = event => {
    event.preventDefault();

    const { company, operation } = props;

      if (!event.target.checkValidity()){
        setValidated(true)
        return;
      }

      const data = new FormData(event.target);
      const response = fetch(urlCompany + "method=updateCompany", {
        method: 'POST',
        body: data,
      }).then(response => {
        setMessage("Company profile updated.")
        setTimeout(() => {  setMessage(null); }, 2000);
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        setMessage('There has been a problem with your fetch operation: ' + error.message)
        setTimeout(() => {  setMessage(null); }, 2000);
      });
    }

    const loadCompany = companyid => {
      axios.get(urlCompany + 'method=getCompanyJSON&CompanyID=' + companyid)
      .then(response => {
        setCompany(response.data[0])
      });
    }

    const loadCompanyUsers = companyid => {
      axios.get(urlUser + '&Company_ID=' + companyid)
      .then(response => {
        setUsers(response.data)
      });
    }

    const loadIndustries = () => {
      axios.get(urlIndustry)
      .then(response => {
        setIndustries(response.data)
      });
    }

    const loadStates = () => {
      axios.get(urlState + '&ListStateActive=1')
      .then(response => {
        setStates(response.data)
      });
    }


      const { companyid } = props;

        return(
          <div className="container companyprofile-container">
            <div className="row hdr">
              <div className="col-lg-12">

              </div>
            </div>
            <div style={{ marginTop: 20 }} className="row col-lg-12">
              <Form noValidate validated={validated} onSubmit={handleSubmit} className="company-profile-form">
              {company && <Form.Control name="COMPANYID" type="hidden" defaultValue={company.COMPANYID} />}
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                  <Tab eventKey="home" title="Company / Organization Profile">
                    {company && users &&
                    <Form.Group controlId="primaryadministrator">
                      <Form.Label>Primary Administrator *</Form.Label>
                      <Form.Control as="select" name="User_ID" required disabled={!isAdmin}>
                      {
                        users.map((user) =>
                          { return <option value={user.USERID} selected={company.USER_ID === user.USERID} >{user.USERFNAME}  {user.USERLNAME}</option> }
                        )
                      }
                      </Form.Control>
                    </Form.Group>}
                    <Form.Group controlId="companyname">
                      <Form.Label>Company/Org Name (D/B/A) *</Form.Label>
                      <Form.Control type="text" name="CompanyName" placeholder="Company Name" defaultValue={company?company.COMPANYNAME:''} required disabled={!isAdmin}/>
                    </Form.Group>
                    <Form.Group controlId="companylegalname">
                      <Form.Label>Legal Name (If different than above) </Form.Label>
                      <Form.Control type="text" name="LegalName" placeholder="Legal Name" disabled={!isAdmin}  />
                    </Form.Group>

                    <Row>
                      <Col>
                        <Form.Group controlId="primarycontactname">
                          <Form.Label>Primary Contact Name *</Form.Label>
                          <Form.Control type="text" name="PrimaryContactName" placeholder="Primary Contact Name" defaultValue={company?company.PRIMARYCONTACTNAME:''} required disabled={!isAdmin} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="primarycontactphone">
                          <Form.Label>Primary Contact Phone *</Form.Label>
                          <Form.Control type="text" name="PrimaryContactPhone" placeholder="Primary Contact Phone" defaultValue={company?company.PRIMARYCONTACTPHONE:''} required disabled={!isAdmin} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="website">
                      <Form.Label>Web Site </Form.Label>
                      <Form.Control type="text" name="WebSite" placeholder="Web Site" defaultValue={company?company.WEBSITE:''} disabled={!isAdmin} />
                    </Form.Group>
                  </Tab>
                  <Tab eventKey="profile" title="Corporate Address">
                    <Form.Group controlId="workphone">
                      <Form.Label>Corporate / Home Phone *</Form.Label>
                      <Form.Control type="text" name="CompanyPhone" placeholder="Corporate / Home Phone" defaultValue={company?company.COMPANYPHONE:''} required disabled={!isAdmin} />
                    </Form.Group>
                    <Form.Group controlId="workaddress1">
                      <Form.Label>Corporate / Home Address *</Form.Label>
                      <Form.Control type="text" name="CompanyAddress1" placeholder="Corporate / Home Address1" defaultValue={company?company.COMPANYADDRESS1:''} required disabled={!isAdmin} />
                    </Form.Group>
                    <Form.Group controlId="workaddress2">
                      <Form.Label>Corporate / Home Address 2</Form.Label>
                      <Form.Control type="text" name="CompanyAddress2" placeholder="Corporate / Home Address2" defaultValue={company?company.COMPANYADDRESS2:''} disabled={!isAdmin} />
                    </Form.Group>
                    <Form.Group controlId="workaddress3">
                      <Form.Label>Corporate / Home Address 3</Form.Label>
                      <Form.Control type="text" name="CompanyAddress3" placeholder="Corporate / Home Address3" defaultValue={company?company.COMPANYADDRESS3:''} disabled={!isAdmin} />
                    </Form.Group>
                    <Form.Group controlId="workcity">
                      <Form.Label>Corporate / Home City *</Form.Label>
                      <Form.Control type="text" name="CompanyCity" placeholder="Corporate / Home City" defaultValue={company?company.COMPANYCITY:''} required disabled={!isAdmin} />
                    </Form.Group>
                    <Form.Group controlId="workpostalcode">
                      <Form.Label>Corporate / Home Postal Code *</Form.Label>
                      <Form.Control type="text" name="CompanyPostalCode" placeholder="Corporate / Home Postal Code" defaultValue={company?company.COMPANYPOSTALCODE:''} disabled={!isAdmin} />
                    </Form.Group>
                    {company && states &&
                    <Form.Group controlId="companystate">
                      <Form.Label>Company State *</Form.Label>
                      <Form.Control as="select" name="CompanyState" disabled={!isAdmin} >
                        <option></option>
                        {
                          states.map((stateitem) =>
                            { return <option value={stateitem.LISTSTATEABB} selected={company && (company.COMPANYSTATE === stateitem.LISTSTATEABB || company.COMPANYSTATE === stateitem.LISTSTATEID) }>{stateitem.LISTSTATE}</option> }
                          )
                        }
                      </Form.Control>
                    </Form.Group>}
                  </Tab>
                </Tabs>
                {message &&
                <><Alert key='infoMessage001' variant='info'>
                  {message}
                </Alert><br /></>}
                <Button variant="primary" type="submit" disabled={!isAdmin}>
                  Update Company
                </Button>
              </Form>
            </div>
          </div>
        )

}

export default CompanyProfile;
