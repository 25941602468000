import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken, getUser } from './Common';

// handle the public routes
//if token doesn't exists, shows Component (Login or Login Reset)
//if token exists, validates USERGROUPID to take the user to its correspondant UI (user or admin)
function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : getUser().USERGROUPID > 2 ? <Redirect to={{ pathname: '/user/home' }} /> :  <Redirect to={{ pathname: '/admin/dashboard' }} />}
    />
  )
}

export default PublicRoute;
