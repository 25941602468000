import React, { useState, useEffect, useRef } from 'react';
import CompanyEntry from './CompanyEntry';
import CompanyEnableDisableModal from './CompanyEnableDisableModal';
import { removeUserSession } from '../../utils/Common';

import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";


function CompanyList(props) {
  const url = "../components/companies.cfc?method=getCompanyJSON";
  const querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [currentURL, setCurrentURL] = useState(null);
  const [myparam, setMyParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([{
                                            dataField: 'COMPANYID',
                                            text: 'ID',
                                            hidden: true
                                          },{
                                            dataField: 'COMPANYCODE',
                                            text: 'Company Code',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'COMPANYNAME',
                                            text: 'Company Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'COMPANYADMIN',
                                            text: 'Company Admin',
                                            sort: true
                                          },
                                          {
                                            dataField: 'SECONDARYACCESS',
                                            text: 'Secondary Access',
                                            sort: true
                                          },
                                          {
                                            dataField: 'COMPANYACTIONS',
                                            text: 'Company',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  < CompanyEntry loadTable = {loadTable} key = {row.COMPANYID} companyid = {row.COMPANYID} company = {row} operation="Edit"/>
                                                  < CompanyEntry loadTable = {loadTable} key = {row.COMPANYID}  companyid = {row.COMPANYID} company = {row} operation="Delete"/>
                                                  < CompanyEnableDisableModal loadTable = {loadTable} key = {row.COMPANYID} company = {row} operation={row.DISABLECOMPANY === true? "Enable" : "Disable" }/>
                                                </div>
                                              </div>
                                            )
                                          },
                                          {
                                            dataField: 'USERACTIONS',
                                            text: 'Users',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <NavLink className="nav-link" to={"/admin/users/company_id/"+row.COMPANYID}>View</NavLink>
                                                </div>
                                              </div>
                                            )
                                          }]);

  const loadTable = query => {
        const { match, location } = props;
        setCurrentURL(location.pathname);
        setLoading(true);

        var getUrl = url;

        if(match.params.companyparamname && match.params.companyparamvalue){
          console.log(match.params.companyparamname);
          console.log(match.params.companyparamvalue);
          getUrl = getUrl + "&" + match.params.companyparamname + "=" + match.params.companyparamvalue
        }

        if(query && query.get("searchCompanyName")){
          console.log(query.get("searchCompanyName"));
          getUrl = getUrl + "&searchCompanyName="+query.get("searchCompanyName")
        }

        axios.get(getUrl)
        .then(response => {
          setRecords(response.data);
        })
        .catch(error => {
          setRecords([]);
          console.log('There has been a problem with your fetch operation: ', error.message);
          if (error.response.status === 401){
            removeUserSession();
            props.history.push('/login');
          }
        });
        setRecords([]);
        setLoading(false);
  }

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      loadTable(querystr);
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      loadTable(querystr)
    }
  }, []);

  const LoadingIndication = () => (
    <Spinner animation="border" />
  );
  const NoDataIndication = () => (
    <>There are no data to display.</>
  );

  if (loading) {
    return <div className="content">Loading...</div>
  }

  const options = {

                        sizePerPageList: [ {
                          text: '10', value: 10
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: records.length
                        } ]

                      };

  return (
    <div className="container">
      <CompanyEntry loadTable = {loadTable}  operation="New"/>
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }}>
      <BootstrapTable striped hover
        keyField='COMPANYID'
        columns={ columns }
        data={ records }
        pagination={ paginationFactory(options) }
        filter={ filterFactory() }
        noDataIndication={loading?() => <LoadingIndication />:() => <NoDataIndication />}>
      </BootstrapTable>
      </div>
    </div>
  )
}

export default withRouter(CompanyList);
