import React, { useState, useEffect, useRef } from 'react';
import ResourceEntry from './ResourceEntry';
import CreateReport from './CreateReport';
import { removeUserSession, getCurrentCompanyUI } from '../../utils/Common';
import axios from 'axios';
import { Spinner, Form } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";
import uuid from 'react-uuid'


function CompanyProjects(props) {

  const formatedTimestamp = datestring => {
    if(datestring && datestring !== ''){
      const d = new Date(datestring)
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      return `${date}`
    } else {
      return ``
    }
  }

  const url = "../components/samples.cfc?method=buildSampleDataSetByDateClientUIJSON";
  const urlCompany = "../components/companies.cfc?method=getCompanyJSON";
  const urlSecondaryCompanies = "../components/companies.cfc?method=companySecodaryAccessUserInterface";
  var querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [currentURL, setCurrentURL] = useState(null);
  const [myparam, setMyParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [secondaryCompanies, setSecondaryCompanies] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const companySelected = React.createRef();
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([{
                                            dataField: 'RESOURCEID',
                                            text: 'ID',
                                            sort: true,
                                            hidden: true
                                          },
                                          {
                                            dataField: 'LABNUMTRIMMED',
                                            text: 'Job #',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'PROJECTNAME',
                                            text: 'Project Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'COLLECT_DATE',
                                            text: 'Date Collected',
                                            sort: true,
                                            sortFunc: (a, b, order, dataField) => {
                                              const datea = new Date(a);
                                              const dateb = new Date(b);
                                              if (order === 'desc') {
                                                return dateb - datea;
                                              } else {
                                                return datea - dateb;
                                              }
                                            },
                                            formatter: (cell, row) => (
                                              <>
                                              {formatedTimestamp(row.COLLECT_DATE)}
                                              </>
                                            ),
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'RECEIVED_DATE',
                                            text: 'Date Received',
                                            sort: true,
                                            sortFunc: (a, b, order, dataField) => {
                                              const datea = new Date(a);
                                              const dateb = new Date(b);
                                              if (order === 'desc') {
                                                return dateb - datea;
                                              } else {
                                                return datea - dateb;
                                              }
                                            },
                                            formatter: (cell, row) => (
                                              <>
                                              {formatedTimestamp(row.RECEIVED_DATE)}
                                              </>
                                            ),
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'RESOURCEVIEW',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <NavLink className="nav-link" to={"/user/resources?searchLabNum=" + row.LABNUMTRIMMED}>View Files</NavLink>
                                                </div>
                                              </div>
                                            )
                                          },
                                          {
                                            dataField: 'RESOURCEACTIONS',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <CreateReport
                                                    key={uuid()}
                                                    labnumtrimmed = {row.LABNUMTRIMMED}
                                                    projectname = {row.PROJECTNAME}
                                                    receiveddate = {row.RECEIVED_DATE}
                                                    collecteddate = {row.COLLECT_DATE}
                                                    companyid = {row.COMPANYID}
                                                    companyname = {row.COMPANYNAME}
                                                    operation = "new"
                                                    />
                                                </div>
                                              </div>
                                            )
                                          }]);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      const { companyid } = props;

      setLoading(true);
      loadCurrentCompanyAndTable();
      //loadCompany();
      //loadSecondaryCompanies();
      setLoading(false);

      mounted.current = true;

    } else {
      // do componentDidUpdate logic
      const { companyid } = props;

      setLoading(true);
      loadCurrentCompanyAndTable();
      //loadCompany();
      //loadSecondaryCompanies();
      setLoading(false);
    }
  }, []);

  const loadCompany = () => {
    setLoading(true);
    const { companyid } = props;
    if(companyid){
      axios.get(urlCompany+"&COMPANYID="+companyid)
      .then(response => {
        setCompany(response.data[0]);
        //loadTable(response.data[0].COMPANYCODE,response.data[0].COMPANYID,response.data[0].COMPANYNAME)
      })
      .catch(error => {
        setRecords([]);
        console.log('There has been a problem with your fetch operation: ', error.message);
        if (error.response.status === 401){
          removeUserSession();
          props.history.push('/login');
        }
      });
    }
    setLoading(false);
  }

  const loadSecondaryCompanies = () => {
    setLoading(true);
    const { companyid } = props;
    if(companyid){
      axios.get(urlSecondaryCompanies+"&COMPANYID="+companyid)
      .then(response => {
        setSecondaryCompanies(response.data);
      })
      .catch(error => {
        setRecords([]);
        console.log('There has been a problem with your fetch operation: ', error.message);
        if (error.response.status === 401){
          removeUserSession();
          props.history.push('/login');
        }
      });
    }
    setLoading(false);
  }

  const loadTable = (companycode,companyid,companyname) => {
        setLoading(true);

        axios.get(url+"&COMPANYCODE="+encodeURIComponent(companycode)+"&COMPANYID="+companyid+"&COMPANYNAME="+encodeURIComponent(companyname))
        .then(response => {
          setRecords(response.data);
        })
        .catch(error => {
          setRecords([]);
          console.log('There has been a problem with your fetch operation: ', error.message);
          if (error.response.status === 401){
            removeUserSession();
            props.history.push('/login');
          }
        });
        setRecords([]);
        setLoading(false);
  }

  const loadCurrentCompanyAndTable = () => {
    const { companyid } = props;


    var currentCompanyID = getCurrentCompanyUI()
    var url = urlCompany;
    if(currentCompanyID){
      url = urlCompany +"&COMPANYID="+ currentCompanyID;
    }else{
      url = urlCompany +"&COMPANYID="+ companyid;
    }
    axios.get(url)
    .then(response => {
      setCompany(response.data[0]);
      loadTable(response.data[0].COMPANYCODE,response.data[0].COMPANYID,response.data[0].COMPANYNAME)
    })
    .catch(error => {
      setRecords([]);
      console.log('There has been a problem with your fetch operation: ', error.message);
      if (error.response.status === 401){
        removeUserSession();
        props.history.push('/login');
      }
    });
  }


  if (loading) {
    return <div className="content">Loading...</div>
  }

  const LoadingIndication = () => (
    <Spinner animation="border" />
  );
  const NoDataIndication = () => (
    <>There are no data to display.</>
  );
  const defaultSorted = [{
    dataField: 'LABNUMTRIMMED', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];

  const options = {

                        sizePerPageList: [ {
                          text: '10', value: 10
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: records.length
                        } ]

                      };

  return (
    <div className="container companyprofile-container">

      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }}>
        <BootstrapTable striped hover
          keyField='RESOURCEID'
          columns={ columns }
          data={ records }
          pagination={ paginationFactory(options) }
          filter={ filterFactory() }
          defaultSorted={defaultSorted}
          noDataIndication={loading?() => <LoadingIndication />:() => <NoDataIndication />}>
        </BootstrapTable>
      </div>
    </div>
  )
}

export default withRouter(CompanyProjects);
