import React, { useState, useEffect, useRef } from 'react';
import ResourceEntry from './ResourceEntry';
import CreateReport from './CreateReport';
import { removeUserSession, setCurrentCompanyUI, getCurrentCompanyUI } from '../../utils/Common';
import axios from 'axios';
import { Spinner, Form, Card } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";
import uuid from 'react-uuid'


function Sidebar(props) {

  const urlCompany = "../components/companies.cfc?method=getCompanyJSON";
  const urlSecondaryCompanies = "../components/companies.cfc?method=companySecodaryAccessUserInterface";
  var querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [currentURL, setCurrentURL] = useState(null);
  const [myparam, setMyParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [secondaryCompanies, setSecondaryCompanies] = useState(null);
  const [selectedCompany,setSelectedCompany] = useState(null);
  const location = useLocation();


  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      const { companyid } = props;

      setLoading(true);
      loadCompany();
      loadSecondaryCompanies();
      setLoading(false);
      console.log('Current Selected Company in Session: ' + getCurrentCompanyUI())
      setSelectedCompany(getCurrentCompanyUI())
      mounted.current = true;

    } else {
      // do componentDidUpdate logic
      const { companyid } = props;

      console.log('Current Selected Company in Session (on remount): ' + getCurrentCompanyUI() )
      setSelectedCompany(getCurrentCompanyUI())
    }
  }, []);

  const loadCompany = () => {
    setLoading(true);
    const { companyid } = props;
    if(companyid){
      axios.get(urlCompany+"&COMPANYID="+companyid)
      .then(response => {
        setCompany(response.data[0]);
      })
      .catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        if (error.response.status === 401){
          removeUserSession();
          props.history.push('/login');
        }
      });
    }
    setLoading(false);
  }

  const loadSecondaryCompanies = () => {
    setLoading(true);
    const { companyid } = props;
    if(companyid){
      axios.get(urlSecondaryCompanies+"&COMPANYID="+companyid)
      .then(response => {
        setSecondaryCompanies(response.data);
      })
      .catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        if (error.response.status === 401){
          removeUserSession();
          props.history.push('/login');
        }
      });
    }
    setLoading(false);
  }


  function handleChangeCompany(e) {
    setCurrentCompanyUI(e.target.value)
    setSelectedCompany(e.target.value)
    props.history.push(location.pathname);

  }

  if (loading) {
    return <div className="content">Loading...</div>
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Company Access</Card.Title>
          <Card.Text>
          <Form>
            <Form.Group controlId="SecondaryCompanies">
              {selectedCompany &&
              <Form.Control key={uuid()} as="select" name="SecondaryCompanyID" onChange={handleChangeCompany}  >
                {company && company.COMPANYID &&
                  <option
                    value={company.COMPANYID}
                    selected={company.COMPANYID.toString() === selectedCompany.toString()} >{
                      company.COMPANYNAME} - {company.COMPANYCODE}
                  </option>}
                {secondaryCompanies?
                  secondaryCompanies.map((secondarycompany) => { return  <option value={secondarycompany.COMPANYID} selected={secondarycompany.COMPANYID.toString() === selectedCompany.toString()} >{secondarycompany.COMPANYNAME} - {secondarycompany.COMPANYCODE}  </option> }):<>Loading</>}
              </Form.Control>}
            </Form.Group>
          </Form>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}

export default withRouter(Sidebar);
