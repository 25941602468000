
import React, { useState } from 'react';
import { Form,Button} from 'react-bootstrap'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


function SidebarResources(props){
  const searchLabNum = useFormInput('');
  //const [searchUploadDate, setSearchUploadDate] = useState(new Date());
  const [searchUploadDate, setSearchUploadDate] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();
    props.history.push(`/admin/resources?searchLabNum=`+encodeURIComponent(searchLabNum.value)+'&searchUploadDate='+encodeURIComponent(searchUploadDate));
  }

  return(
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="searchLabNum">
        <Form.Label>Job #</Form.Label>
        <Form.Control type="text" name="searchLabNum" placeholder="Job #" {...searchLabNum} />
      </Form.Group>
      <Form.Group controlId="startdate">
        <Form.Label >Upload Date</Form.Label>
        <DatePicker selected={searchUploadDate} onChange={date => setSearchUploadDate(date)} />
      </Form.Group>
      <Button variant="primary" type="submit" >
          Search
      </Button>
    </Form>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default SidebarResources;
