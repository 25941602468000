import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap'
import { useLocation, withRouter } from "react-router-dom";


function RegLimitsUpdate(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);


  const handleSubmit = event =>  {

    event.preventDefault();

    setError(null);
    setLoading(true);

    var url = '../components/utils.cfc?method=updateRegLimits';

    const response = fetch(url, {
      method: 'GET'
    }).then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      setResults(data)
    }).catch(error => {
      this.setState({ errorMessage: error.toString() });
      console.error('There was an error!', error);
    });
    setLoading(false);
  }

  return (
    <div className="container">
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }} >
        <Form noValidate onSubmit={handleSubmit}>
          To update the existing reglimits table select
              <Button   variant="link" type="submit" >
                  {loading ? 'Loading...' : 'Go'}
              </Button>
        </Form>
          {loading && <>Loading...</>}
          {(results && results.success) &&
            <>
            <br />
            <br />
            <strong>{results.total_reg_limits_updated} Reg Limits updated</strong><br/>
          </>}
          {(results && results.error) &&
            <>
            <br/>
            <br/>
            <strong>Error:{results.message}</strong> <br/>
          </>}
      </div>
    </div>
  );
}

export default withRouter(RegLimitsUpdate);
