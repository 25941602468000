import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Form, Tabs,Tab,Button,Modal, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const urlResource = "../components/resources.cfc?";

function ResourceEntry (props){

  const mounted = useRef();
  const [showHide, setShowHide] = useState(false);
  const [file, setFile] = useState(false);
  const [result, setResult] = useState(false);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = event => {
      event.preventDefault();

      const { resource, operation } = props;

      // Delete Resource
      if(operation.toString().toLowerCase() === 'delete'){

        const response = fetch(urlResource + "method=deleteResource&ResourceID="+resource.RESOURCEID)
        .then(response => {
          setMessage("Resource Deleted")
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        }).catch(error => {
          console.log('There has been a problem with your fetch operation: ', error.message);
          setMessage('There has been a problem with your fetch operation: '+error.message);
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        });

      }
      // Update Resource
      else if(operation.toString().toLowerCase() === 'edit'){

        if (!event.target.checkValidity()){
          setValidated(true)
          return;
        }

        const data = new FormData(event.target);

        const response = fetch(urlResource + "method=updateResource", {
          method: 'POST',
          body: data,
        }).then(response => {
          setMessage("Resource Updated")
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        }).catch(error => {
          console.log('There has been a problem with your fetch operation: ', error.message);
          setMessage('There has been a problem with your fetch operation: '+error.message);
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        });

      }
      // Insert New Resource
      else if(operation.toString().toLowerCase() === 'new'){

        const data = new FormData(event.target);

        const response = axios.post(urlResource + "method=insertResource", data, { headers: {'content-type': 'multipart/form-data' } } ).then(response => {
          setResult(response.data)

          if(response.success){
            setMessage("New Resource Added")
            setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
          }
        }).catch(error => {
          console.log('There has been a problem with your fetch operation: ', error.message);
          setMessage('There has been a problem with your fetch operation: '+error.message);
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        });
      }else{
        console.log("Unsupported operation '" + operation.toString().toLowerCase() + "'");
      }
    }

    const onChange = e => {
      setFile(e.target.files[0])
    }

    const handleModalShowHide = () => {
      setShowHide(!showHide)
    }


      const { resource, operation } = props;

        return(
            <div>
                {operation === 'New' && <Button variant="link" className="btn-new-object" onClick={() => handleModalShowHide()}>
                    New Resource
                </Button>}
                {operation === 'Edit' && <Button variant="primary" onClick={() => handleModalShowHide()}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>}
                {operation === 'Delete' && <Button variant="danger" onClick={() => handleModalShowHide()}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>}

                <Modal show={showHide}>
                    <Form onSubmit={handleSubmit} >
                      {resource && <Form.Control name="RESOURCEID" type="hidden" defaultValue={resource.RESOURCEID} />}
                      <Modal.Header closeButton onClick={() => handleModalShowHide()}>
                      <Modal.Title>{operation==='New'?"New Resource":operation + " " + resource.PROJECTNAME + " " + resource.RESOURCETITLE}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {operation === 'New' &&
                        <><Form.Control type='hidden' name="method" defaultValue='insertResource' /></>}
                        <Form.Group controlId="labNum">
                          <Form.Label>Lab #</Form.Label>
                          <Form.Control type="text" name="labNum" placeholder="Lab Num" defaultValue={resource?resource.LABNUM:''} />
                        </Form.Group>
                        {operation !== 'New' &&
                        <><Form.Group controlId="projectName">
                        < Form.Label>Project Name</Form.Label>
                          <Form.Control type="text" name="projectName" placeholder="Project Name" defaultValue={resource?resource.PROJECTNAME:''} />
                        </Form.Group></>}
                        <Form.Group controlId="resourceTitle">
                        < Form.Label>Title</Form.Label>
                          <Form.Control type="text" name="resourceTitle" placeholder="Title" defaultValue={resource?resource.RESOURCETITLE:''} />
                        </Form.Group>
                        <Form.Group controlId="ResourceSummary">
                          <Form.Label>Summary</Form.Label>
                            <Form.Control type="text" name="resourceSummary" placeholder="Summary" defaultValue={resource?resource.RESOURCESUMMARY:''} />
                        </Form.Group>
                        {operation !== 'New' &&
                        <><Form.Group controlId="resourceFile">
                          <Form.Label>File Name</Form.Label>
                          <Form.Control type="text" name="resourceFile" placeholder="File Name" defaultValue={resource?resource.RESOURCEFILE:''} required />
                        </Form.Group></>}
                        <Form.Group controlId="resourceFileUpload">
                          {operation === 'New' && <Form.File label="File" name="resourceFile" onChange={ onChange } />}
                          {operation === 'Edit' && <><Form.Label>File</Form.Label><a href={"../../docs/resources/" + resource.RESOURCEFILE} target="_blank">View</a></>}
                          {operation === 'Delete' && <><Form.Label>File</Form.Label><a href={"../../docs/resources/" + resource.RESOURCEFILE} target="_blank">View</a></>}
                        </Form.Group>
                        {message &&
                        <><Alert key='infoMessage001' variant='info'>
                          {message}
                        </Alert><br /></>}
                      </Modal.Body>
                      <Modal.Footer>
                      <Button variant="secondary" onClick={() => handleModalShowHide()}>
                          Close
                      </Button>
                      {operation === 'New' && <Button variant="primary" type="submit" >
                          Insert
                      </Button>}
                      {operation === 'Edit' && <Button variant="primary" type="submit" >
                          Save Changes
                      </Button>}
                      {operation === 'Delete' && <Button variant="danger" type="submit" >
                          Delete
                      </Button>}
                      </Modal.Footer>
                    </Form>
                </Modal>

            </div>
        )


}

export default ResourceEntry;
