import React, { useState, useEffect, useRef } from 'react';
import ResourceEntry from './ResourceEntry';
import { removeUserSession } from '../../utils/Common';
import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";


function ResourceList(props) {

  const formatedTimestamp = datestring => {
    if(datestring && datestring !== ''){
      const d = new Date(datestring)
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      return `${date} ${time}`
    } else {
      return ``
    }
  }

  const url = "../components/resources.cfc?method=getResourcesJSON";
  var querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [currentURL, setCurrentURL] = useState(null);
  const [myparam, setMyParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([{
                                            dataField: 'RESOURCEID',
                                            text: 'ID',
                                            hidden: true
                                          },
                                          {
                                            dataField: 'LABNUM',
                                            text: 'Job #',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'PROJECTNAME',
                                            text: 'Project Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'RESOURCEDATEMODIFIED',
                                            text: 'Upload Date',
                                            sort: true,
                                            sortFunc: (a, b, order, dataField) => {
                                              const datea = new Date(a);
                                              const dateb = new Date(b);
                                              if (order === 'desc') {
                                                return dateb - datea;
                                              } else {
                                                return datea - dateb;
                                              }
                                            },
                                            formatter: (cell, row) => (
                                              <>
                                              {formatedTimestamp(row.RESOURCEDATEMODIFIED)}
                                              </>
                                            )
                                          },
                                          {
                                            dataField: 'RESOURCETITLE',
                                            text: 'Title',
                                            sort: true
                                          },
                                          {
                                            dataField: 'RESOURCEFILE',
                                            text: 'File Name',
                                            sort: true
                                          },
                                          {
                                            dataField: 'RESOURCEFILESIZE',
                                            text: 'File Size',
                                            sort: true
                                          },
                                          {
                                            dataField: 'RESOURCESUMMARY',
                                            text: 'Summary',
                                            sort: true
                                          },
                                          {
                                            dataField: 'RESOURCEVIEW',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <a href={"../docs/resources/" + row.RESOURCEFILE} download>View</a>
                                                </div>
                                              </div>
                                            )
                                          },
                                          {
                                            dataField: 'RESOURCEACTIONS',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <ResourceEntry
                                                    loadTable = {loadTable}
                                                    key = {row.RESOURCEID}
                                                    resourceid = {row.RESOURCEID}
                                                    resource = {row}
                                                    operation="Delete"/>
                                                </div>
                                              </div>
                                            )
                                          }]);


  const loadTable = query => {
        const { match, location } = props;
        setCurrentURL(location.pathname);
        setLoading(true);


        var getUrl = url;

        if(match.params.resourceparamname && match.params.resourceparamvalue){
          getUrl = getUrl + "&" + match.params.resourceparamname + "=" + match.params.resourceparamvalue
        }

        if(query && query.get("searchLabNum")){
          console.log(query.get("searchLabNum"));
          getUrl = getUrl + "&searchLabNum="+querystr.get("searchLabNum")
        }
        if(query && query.get("searchUploadDate")){
          console.log(query.get("searchUploadDate"));
          getUrl = getUrl + "&searchUploadDate="+querystr.get("searchUploadDate")
        }

        axios.get(getUrl)
        .then(response => {
          setRecords(response.data);
        })
        .catch(error => {
          setRecords([]);
          console.log('There has been a problem with your fetch operation: ', error.message);
          if (error.response.status === 401){
            removeUserSession();
            props.history.push('/login');
          }
        });
        setRecords([]);
        setLoading(false);
  }

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      loadTable(querystr);
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      loadTable(querystr)
    }
  }, []);

  if (loading) {
    return <div className="content">Loading...</div>
  }

  const LoadingIndication = () => (
    <Spinner animation="border" />
  );
  const NoDataIndication = () => (
    <>There are no data to display.</>
  );
  const defaultSorted = [{
    dataField: 'LABNUM', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];

  const options = {

                        sizePerPageList: [ {
                          text: '10', value: 10
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: records.length
                        } ]

                      };

  return (
    <div className="container">
      <ResourceEntry loadTable = {loadTable}  operation="New"/>
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }}>
        <BootstrapTable striped hover
          keyField='RESOURCEID'
          columns={ columns }
          data={ records }
          pagination={ paginationFactory(options) }
          filter={ filterFactory() }
          defaultSorted={defaultSorted}
          noDataIndication={loading?() => <LoadingIndication />:() => <NoDataIndication />}>
        </BootstrapTable>
      </div>
    </div>
  )
}

export default withRouter(ResourceList);
