import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';

function ForgotPasswordForm(props) {
  const [loading, setLoading] = useState(false);

  const usernameForgotPassword = useFormInput('');
  const [error, setError] = useState(null);
  const [resetRequestSent, setResetRequestSent] = useState(null);

  const handleSubmitForgotPassword = event => {
    event.preventDefault();

    setResetRequestSent(null);
    setError(null);
    setLoading(true);

    var url = '../components/appsession.cfc?';

    axios.get(url + 'method=updateUserPasswordResetRequest&UserName=' + encodeURIComponent(usernameForgotPassword.value))
    .then(response => {
      setLoading(false);
      if (response.status === 200 ) setResetRequestSent(response.data);
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError("Username or Password is wrong");
      else setError("Something went wrong. Please try again later.");
    });
  }

  return (
    <>
      <div>Use the form below to have your password sent to your email address on record.</div>
      <Form onSubmit={handleSubmitForgotPassword}>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control type="text" name="username" className="fadeIn second" {...usernameForgotPassword} />
        </Form.Group>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
        {(resetRequestSent && resetRequestSent.success) && <><small style={{ color: 'green' }}>{resetRequestSent.message}</small><br /></>}<br />
        {(resetRequestSent && resetRequestSent.error) && <><small style={{ color: 'red' }}>{resetRequestSent.message}</small><br /></>}<br />
        <Button variant="primary" className="fadeIn fourth" type="submit" disabled={loading}>
          Send
        </Button>
      </Form>
  </>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default ForgotPasswordForm;
