import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Form, Tabs,Tab,Button,Modal, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getUserID, getUser } from '../../utils/Common';
import uuid from 'react-uuid'

const urlCompany = "../components/companies.cfc?method=getCompanyJSON";
const urlUser = "../components/users.cfc?";

function UserEntry(props) {

  const mounted = useRef();
  const [showHide, setShowHide] = useState(false);
  const [users, setUsers] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [userCompanies, setUserCompanies] = useState(false);
  const [states, setStates] = useState(false);
  const [industries, setIndustries] = useState(false);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
      if (!mounted.current) {
        // do componentDidMount logic
        const { user, operation, userid } = props

        if(user && operation.toString().toLowerCase() !== 'new'){
            loadUserCompanies();
        }
        console.log("Company ID for the current logged User is " + JSON.stringify(getUser()))
        loadCompanies();
        mounted.current = true;
      } else {
        // do componentDidUpdate logic

      }
    }, []);

  const handleSubmit = event => {
      event.preventDefault();

      const { user, operation } = props;

      // Delete User
      if(operation.toString().toLowerCase() === 'delete'){

        const response = fetch(urlUser + "method=updateUser&HideUser=1&UserID="+user.USERID+"&Company_ID="+user.COMPANY_ID)
        .then(response => {
          setMessage("User Deleted")
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        }).catch(error => {
          console.log('There has been a problem with your fetch operation: ', error.message);
          setMessage('There has been a problem with your fetch operation: '+error.message);
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        });
      }
      // Update User
      else if(operation.toString().toLowerCase() === 'edit'){

        if (!event.target.checkValidity()){
          setValidated(true)
          return;
        }

        const data = new FormData(event.target);

        const response = fetch(urlUser + "method=updateUser", {
          method: 'POST',
          body: data,
        }).then(response => {
          setMessage("User Updated")
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        }).catch(error => {
          console.log('There has been a problem with your fetch operation: ', error.message);
          setMessage('There has been a problem with your fetch operation: '+error.message);
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        });
      }
      // New User
      else if(operation.toString().toLowerCase() === 'new'){
        if (!event.target.checkValidity()){
          setValidated(true)
          return;
        }

        const data = new FormData(event.target);

        const response = fetch(urlUser + "method=insertUser", {
          method: 'POST',
          body: data,
        }).then(response => {
          setMessage("User Updated")
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        }).catch(error => {
          console.log('There has been a problem with your fetch operation: ', error.message);
          setMessage('There has been a problem with your fetch operation: '+error.message);
          setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
        });
      }else{
        console.log("Unsupported operation '" + operation.toString().toLowerCase() + "'");
      }
    }

    const loadCompanies = () => {
      axios.get(urlCompany)
      .then(response => {
        setCompanies(response.data)
      });
    }

    const loadUserCompanies = () => {
      const { user } = props;
      axios.get(urlCompany+"&CompanyID="+user.COMPANY_ID)
      .then(response => {
        setUserCompanies(response.data)
      });
    }

    const handleModalShowHide = () => {
      setShowHide(!showHide)
    }


    const { user, operation, userid } = props;
    if (getUser().ISADMIN === 1 || user && user.USERID.toString() === getUserID().toString()) {
        return(
          <div>
                {getUser().ISADMIN === 1 && operation.toString().toLowerCase() === 'new' && <Button variant="link" className="btn-new-object" onClick={() => handleModalShowHide()}>
                    New User
                </Button>}
                {operation.toString().toLowerCase() === 'edit' && <Button variant="primary" onClick={() => handleModalShowHide()}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>}
                {getUser().ISADMIN === 1 && operation.toString().toLowerCase() === 'delete' && <Button variant="danger" onClick={() => handleModalShowHide()}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>}

                <Modal show={showHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Form noValidate validated={validated} autoComplete="off" onSubmit={handleSubmit}>
                      {user && <Form.Control name="USERID" type="hidden" defaultValue={user.USERID} />}
                      <Form.Control name="company_id" type="hidden" defaultValue={getUser().COMPANYID} />
                      <Modal.Header closeButton onClick={() => handleModalShowHide()}>
                      <Modal.Title>{operation.toString().toLowerCase()==='new'?"New User":operation + " " + user.USERFNAME + " " + user.USERLNAME}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group controlId="workTitle">
                          <Form.Label>Work Title</Form.Label>
                          <Form.Control type="text" name="workTitle" placeholder="Title" defaultValue={user?user.WORKTITLE:''} />
                        </Form.Group>
                        <Form.Group controlId="userFName">
                          <Form.Label>First Name *</Form.Label>
                          <Form.Control type="text" name="userFName" placeholder="First Name" defaultValue={user?user.USERFNAME:''} required />
                        </Form.Group>
                        <Form.Group controlId="UserMName">
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control type="text" name="userMName" placeholder="Middle Name" defaultValue={user?user.USERMNAME:''} />
                        </Form.Group>
                        <Form.Group controlId="userLName">
                          <Form.Label>Last Name *</Form.Label>
                          <Form.Control type="text" name="userLName" placeholder="Last Name" defaultValue={user?user.USERLNAME:''} required />
                        </Form.Group>
                        <Form.Group controlId="userName">
                          <Form.Label>Email Address *</Form.Label>
                          <Form.Control type="email" name="userName" placeholder="Email Address" autoComplete="off" defaultValue={user?user.USERNAME:''} required />
                        </Form.Group>
                        <Form.Group controlId="UserPassword">
                          <Form.Label>Password (Only enter a password when you want to change it)</Form.Label>
                          <Form.Control type="password" name="UserPassword" placeholder="Password" autoComplete="new-password" defaultValue="" />
                        </Form.Group>
                        <Form.Group controlId="workPhone">
                          <Form.Label>Work Phone</Form.Label>
                          <Form.Control type="text" name="workPhone" placeholder="Work Phone" defaultValue={user?user.WORKPHONE:''} />
                        </Form.Group>
                        <Form.Group controlId="workPhoneExt">
                          <Form.Label>Work Phone Ext</Form.Label>
                          <Form.Control type="text" name="workPhoneExt" placeholder="Work Phone Ext" defaultValue={user?user.WORKPHONEEXT:''} />
                        </Form.Group>
                        {message &&
                        <><Alert key='infoMessage001' variant='info'>
                          {message}
                        </Alert><br /></>}
                      </Modal.Body>
                      <Modal.Footer>
                      <Button variant="secondary" onClick={() => handleModalShowHide()}>
                          Close
                      </Button>
                      {getUser().ISADMIN === 1 && operation === 'New' && <Button variant="primary" type="submit" >
                          Insert
                      </Button>}
                      {operation === 'Edit' && <Button variant="primary" type="submit" >
                          Save Changes
                      </Button>}
                      {getUser().ISADMIN === 1 && operation === 'Delete' && <Button variant="danger" type="submit" >
                          Delete
                      </Button>}
                      </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        )
      }else{
        return(
          <div>

          </div>
        )
      }

}

export default UserEntry;
