import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Form, Button, Row, Col } from 'react-bootstrap'

function DashboardFileUpload(props) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [labNum, setLabNum] = useState(null);
  const [resourceTitle, setResourceTitle] = useState(null);
  const [resourceSummary, setResourceSummary] = useState(null);
  const [resourceFile, setResourceFile] = useState(null);

  const [result, setResult] = useState(null);

  useEffect(() => {

  }, []);

  const handleSubmit = event =>  {

    event.preventDefault();

    setError(null);
    setLoading(true);

    var url = '../components/resources.cfc?method=insertResource';
    //url = url + '&labNum=' + labNum +
    //      '&resourceTitle=' + resourceTitle +
    //      '&resourceSummary=' + resourceSummary;

    const data = new FormData(event.target);
    //data.append('resourceFile',resourceFile);

    const response = axios.post(url, data,
                                {
                                  headers: {
                                    'content-type': 'multipart/form-data'
                                    }
                                })
                          .then(async response => {
                                const data = await response.data;
                                console.log(data);

                                // check for error response
                                if (response.status !== 200) {
                                  // get error message from body or default to response status
                                  const error = (data && data.message) || response.status;
                                  return Promise.reject(error);
                                }

                                setResult(data)
                              })
                          .catch(error => {
                                setError({ errorMessage: error.toString() });
                                console.log('There was an error!', error);
                              });
    setLoading(false);
  }
  const onChangeLabNum = e => {
      setLabNum(e.target.value)
  }
  const onChangeResourceTitle = e => {
      setResourceTitle(e.target.value)
  }
  const onChangeResourceSummary = e => {
      setResourceSummary(e.target.value)
  }
  const onChangeResourceFile = e => {
      setResourceFile(e.target.files[0])
  }

  return (
    <Form name="fileuploadForm" noValidate onSubmit={handleSubmit}>
      <Form.Group  as={Row} controlId="labNum">
        <Form.Label column sm="2">Job #</Form.Label>
        <Col sm="10">
          <Form.Control type="text" name="labNum" placeholder="Job #" onChange={onChangeLabNum} />
        </Col>
      </Form.Group>
      <Form.Group  as={Row} controlId="resourceTitle">
        <Form.Label column sm="2">Title</Form.Label>
        <Col sm="10">
          <Form.Control type="text" name="resourceTitle" placeholder="Title" onChange={onChangeResourceTitle} />
        </Col>
      </Form.Group>
      <Form.Group  as={Row} controlId="ResourceSummary">
        <Form.Label column sm="2">Summary</Form.Label>
        <Col sm="10">
          <Form.Control type="text" name="resourceSummary" placeholder="Summary" onChange={onChangeResourceSummary} />
        </Col>
      </Form.Group>
      <Form.Group  as={Row} controlId="resourceFileUpload">
        <Form.File label="File" name="resourceFile" onChange={onChangeResourceFile}/>
      </Form.Group>
      <Button variant="primary" type="submit" >
          Submit
      </Button>
      {loading && <>Loading...</>}
      {(result && result.message) &&
        <>
          <br />
          {result.message}
        </>
      }
    </Form>
  );
}

export default DashboardFileUpload;
