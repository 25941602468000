import React from 'react';
import {Dropdown} from 'react-bootstrap';
import { getUser, removeUserSession } from '../../utils/Common';
import axios from 'axios';


function Logout(props){
  const user = getUser();

  // handle click event of logout button
  const handleLogout = event => {
    event.preventDefault();
    removeUserSession();
    axios.get(`../components/appsession.cfc?method=logout`)
    .then(response => {
      console.log(response);
    });
    props.history.push('/login');
  }

  return(
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {user.USERNAME}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default Logout;
