import React, { useState, useEffect, useRef } from 'react';
import UserEntry from './UserEntry';
import UserEnableDisableModal from './UserEnableDisableModal';
import { removeUserSession } from '../../utils/Common';

import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";


function UserList(props) {

  const formatedTimestamp = datestring => {
    if(datestring && datestring !== ''){
      const d = new Date(datestring)
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      return `${date} ${time}`
    } else {
      return ``
    }
  }

  const url = "../components/users.cfc?method=getUsersJSON";
  const querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [currentURL, setCurrentURL] = useState(null);
  const [myparam, setMyParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([{
                                            visible: false,
                                            dataField: 'USERID',
                                            text: 'ID',
                                            hidden: true
                                          },
                                          {
                                            dataField: 'USERFNAME',
                                            text: 'First Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'USERLNAME',
                                            text: 'Last Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'COMPANYNAME',
                                            text: 'Company Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'COMPANYCODE',
                                            text: 'Company Code',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'USERDATELASTLOGIN',
                                            text: 'Last Login',
                                            filter: textFilter(),
                                            sort: true,
                                            sortFunc: (a, b, order, dataField) => {
                                              var datea = new Date("1998-01-01");
                                              var dateb = new Date("1998-01-01");

                                              if(a && a!==''){
                                                datea = new Date(a);
                                              }

                                              if(b && b!==''){
                                                dateb = new Date(b);
                                              }

                                              if (order === 'desc') {
                                                return dateb - datea;
                                              } else {
                                                return datea - dateb;
                                              }
                                            },
                                            formatter: (cell, row) => (
                                              <>
                                              {formatedTimestamp(row.USERDATELASTLOGIN)}
                                              </>
                                            )
                                          },
                                          {
                                            dataField: 'USERACTIONS',
                                            text: 'User',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  < UserEntry
                                                    loadTable = {loadTable}
                                                    key = {row.USERID}
                                                    userid = {row.USERID}
                                                    user = {row}
                                                    operation="Edit"/>
                                                  < UserEntry
                                                    loadTable = {loadTable}
                                                    key = {row.USERID}
                                                    userid = {row.USERID}
                                                    user = {row}
                                                    operation="Delete"/>
                                                  < UserEnableDisableModal
                                                    loadTable = {loadTable}
                                                    key = {row.USERID}
                                                    user = {row}
                                                    operation={row.DISABLEUSER === true? "Enable" : "Disable" }/>
                                                </div>
                                              </div>
                                            )
                                          },
                                          {
                                            dataField: 'COMPANYACTIONS',
                                            text: 'Company',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <NavLink className="nav-link" to={"/admin/companies/companyid/"+row.COMPANY_ID}>View</NavLink>
                                                </div>
                                              </div>
                                            )
                                          }]);

  const loadTable = query => {
        const { match, location } = props;
        setCurrentURL(location.pathname);
        setLoading(true);
        if(match && match.params && match.params.useroperation){
          setMyParam(match.params.useroperation)
        }

        var getUrl = url;

        if(match.params.userparamname && match.params.userparamvalue){
          console.log(match.params.userparamname);
          console.log(match.params.userparamvalue);
          getUrl = getUrl + "&" + match.params.userparamname + "=" + match.params.userparamvalue
        }

        if(query && query.get("searchFName")){
          console.log(query.get("searchFName"));
          getUrl = getUrl + "&searchFName="+query.get("searchFName")
        }
        if(query && query.get("searchLName")){
          console.log(query.get("searchLName"));
          getUrl = getUrl + "&searchLName="+query.get("searchLName")
        }

        axios.get(getUrl)
        .then(response => {
          setRecords(response.data);
        })
        .catch(error => {
          setRecords([]);
          console.log('There has been a problem with your fetch operation: ', error.message);
          if (error.response.status === 401){
            removeUserSession();
            props.history.push('/login');
          }
        });
        setRecords([]);
        setLoading(false);
  }

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      loadTable(querystr);
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      loadTable(querystr)
    }
  }, []);

  if (loading) {
    return <div className="content">Loading...</div>
  }

  const LoadingIndication = () => (
    <Spinner animation="border" />
  );
  const NoDataIndication = () => (
    <>There are no data to display.</>
  );

  const defaultSorted = [{
    dataField: 'USERDATELASTLOGIN', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];

  const options = {

                        sizePerPageList: [ {
                          text: '10', value: 10
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: records.length
                        } ]

                      };

  return (
    <div className="container">
      <UserEntry loadTable = {loadTable}  operation="New"/>
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }}>
      <BootstrapTable striped hover
        keyField='USERID'
        columns={ columns }
        data={ records }
        pagination={ paginationFactory(options) }
        defaultSorted={ defaultSorted }
        filter={ filterFactory() }
        noDataIndication={loading?() => <LoadingIndication />:() => <NoDataIndication />}>
      </BootstrapTable>
      </div>
    </div>
  )
}

export default withRouter(UserList);
