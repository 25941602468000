import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import { useLocation, withRouter } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";

function LabUpdate(props) {
  const [loading, setLoading] = useState(false);
  const labnum = useFormInput('');
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleSubmit = event =>  {
    event.preventDefault();

    setError(null);
    setLoading(true);

    var url = '../components/utils.cfc?';

    axios.get(url + 'method=labUpdate&LabNumQ=' + encodeURIComponent(labnum.value))
    .then(response => {
      setLoading(false);
      console.log(response.data)
      setResults(response.data);
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError("Unauthorized");
      else setError("Something went wrong. Please try again later.");
    });
  }

  /* below, lines taken out from line 50 to debug/dump data after the labUpdate */
  /* <JsonToTable json={results.query_samples} /> */
  /* <JsonToTable json={results.allInserts} /> */
  return (
    <div className="container">
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }}>
        {(results && results.success) &&
          <>
          <strong>Samples Inserted:</strong> {results.samples_inserted}<br />
          <strong>Test Groups Inserted:</strong> {results.test_groups_inserted}<br />
          <strong>Tests Inserted:</strong> {results.tests_inserted}<br />          
          </>}
          {(results && results.error) &&
            <><div>
              {results.message}
            </div></>}
        <p>Use the form below to enter in a Job # you want updated. If the Job # does not currently exist within the extranet it will be inserted.</p>
        <p>Ex. If you want to update or insert Job # 14133-001 you would enter in 14133. This will update all data within that lab number, such as  14133-001 and  14133-002</p>
        <Form className="col-sm-9" noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="company_id">
            <Form.Label className="col-sm-2">Job #</Form.Label>
            <Form.Control className="col-sm-2" type="text" name="LabNum" placeholder="Job #" {...labnum}/>
            <Button  className="col-sm-2" variant="primary" type="submit" >
                {loading ? 'Loading...' : 'Submit'}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default withRouter(LabUpdate);
