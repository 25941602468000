import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Form, Tabs,Tab,Button,Modal, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid'
import { getUserID } from '../../utils/Common';

const urlCompany = "../components/companies.cfc?method=getCompanyJSON";
const urlUser = "../components/users.cfc?";
const urlCompanyReportAccessList = "../components/reportAccess.cfc?method=getCompanyReportAccessList";
const urlReportTypes = "../components/reportAccess.cfc?method=getReportTypesJSON";
const urlDataReports = "../components/dataReports.cfc?method=getReportDataJSON";
const urlGenerateExcel = "../components/generateExcel.cfc?method=generateXLSDownloadNEW";

function CreateReport(props) {

  const { companyid, companyname, labnumtrimmed, projectname, receiveddate, collecteddate, operation } = props
  const mounted = useRef();
  const [loading, setLoading] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [users, setUsers] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [userCompanies, setUserCompanies] = useState(false);
  const [states, setStates] = useState(false);
  const [industries, setIndustries] = useState(false);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState(null);
  const [reportTypeSelected, setReportTypeSelected] = useState(null);
  const [companyReportAccessList, setCompanyReportAccessList] = useState(null);
  const [reportTypes, setReportTypes] = useState(null);
  const [reportToDownload, setReportToDownload] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
      if (!mounted.current) {
        // do componentDidMount logic
        loadCompanyReportAccessList();
        mounted.current = true;
        setUserId(getUserID())
      } else {
        // do componentDidUpdate logic
        loadCompanyReportAccessList();
      }
    }, []);

    const loadCompanyReportAccessList = () => {
      if(companyid){
        axios.get(urlCompanyReportAccessList + '&Company_ID=' + companyid)
        .then(response => {
          setCompanyReportAccessList(response.data)

          loadReportTypes(response.data)
        });
      }
    }

    const loadReportTypes = reportAccessList => {

      axios.get(urlReportTypes + '&reportAccessList=' + encodeURIComponent(reportAccessList))
      .then(response => {
        setReportTypes(response.data)
      });
    }

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        const { labnumtrimmed, operation } = props;

        if(!reportTypeSelected){
          console.log('Please select a report type.')
          setMessage('Please select a report type.')
          setLoading(false);
          return;
        }
        setMessage(null)

        // Create Reports
        if(operation.toString().toLowerCase() === 'new'){
          if (!event.target.checkValidity()){
            setValidated(true)
            return;
          }

          axios.get(urlDataReports+"&LabNum="+labnumtrimmed+"&ReportType="+reportTypeSelected)
          .then(response => {
            console.log(response.data);
            console.log(response.data.length);

            if( response.data.length && response.data.length > 0 ){
              setMessage("Total Records: " + response.data.length + " in Report")
              axios.get(
                urlGenerateExcel +
                "&LabNum=" + labnumtrimmed +
                "&ReportType=" + reportTypeSelected +
                "&ProjectName=" + projectname +
                "&CompanyName=" + companyname)
              .then(response => {
                console.log(response.data);
                setReportToDownload(response.data);
              })
              .catch(error => {

              });
            }else{
              setMessage("No records found in Report")
            }
          })
          .catch(error => {

          });
        }else{
          console.log("Unsupported operation '" + operation.toString().toLowerCase() + "'");
        }
        setLoading(false);
      }

    const trackReportDownload = () => {

			var url = `../components/usage.cfc?method=insertUsage&UsageType_ID=3&UsageValue=${reportToDownload}`;
			// when button is pushed, POST data to remote ColdFusion component method
      axios.get(encodeURI(url))
      .then(response => {
        window.location.assign(encodeURI("../docs/reports/" + reportToDownload));
      })
      .catch(error => {
        console.log('Something went wrong');
      });
    }

    const handleChangeReportType = e => {
        console.log(e.target.value);
        setReportTypeSelected(e.target.value);
    }

    const handleModalShowHide = () => {
      setMessage(null)
      setReportToDownload(null)
      setShowHide(!showHide)
    }

    const formatDate = date => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    }


        return(
            <div>
                {operation.toString().toLowerCase() === 'new' && <Button variant="link" className="create-reports-button" onClick={() => handleModalShowHide()}>
                    Create Report
                </Button>}

                <Modal show={showHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Modal.Header closeButton onClick={() => handleModalShowHide()}>
                      <Modal.Title>Job #: {labnumtrimmed}: {projectname}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group controlId="labnumtrimmed">
                          <Form.Label>Job #</Form.Label>
                          <Form.Control type="text" name="labnumtrimmed" defaultValue={labnumtrimmed?labnumtrimmed:''} disabled />
                        </Form.Group>
                        <Form.Group controlId="projectName">
                          <Form.Label>Project Name</Form.Label>
                          <Form.Control type="text" name="projectName" defaultValue={projectname?projectname:''} disabled />
                        </Form.Group>
                        <Form.Group controlId="projectName">
                          <Form.Label>Collection Date</Form.Label>
                          <Form.Control type="text" name="receiveddate" defaultValue={receiveddate?formatDate(receiveddate):''} disabled />
                        </Form.Group>
                        <Form.Group controlId="projectName">
                          <Form.Label>Received Date</Form.Label>
                          <Form.Control type="text" name="collecteddate" defaultValue={collecteddate?formatDate(collecteddate):''} disabled />
                        </Form.Group>
                        <Form.Group controlId="reportType" >
                          <Form.Label> Pick Report Type </Form.Label>
                          {reportTypes?
                            reportTypes.map((currentReportType) =>
                              { return <Form.Check
                                          key={currentReportType.REPORTTYPEID}
                                          type="radio"
                                          defaultValue={currentReportType.REPORTTYPEID}

                                          onChange={e => setReportTypeSelected(e.currentTarget.value)}
                                          name="reportType"
                                          label={currentReportType.REPORTNAME}/> }
                            ):<>Loading...</>
                          }
                        </Form.Group>
                        {message &&
                        <><Alert key='infoMessage001' variant='info'>
                          {message}
                        </Alert><br /></>}
                        {loading &&
                        <div>
                          Loading...
                        </div>}
                        {reportToDownload &&
                        <>
                        <div ml-auto>
                          <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                            <Button variant="link" onClick={() => trackReportDownload()}>
                                Download {reportToDownload}
                            </Button>
                          </div>
                        </div>
                        </>}
                      </Modal.Body>
                      <Modal.Footer>
                      <Button variant="secondary" onClick={() => handleModalShowHide()}>
                          Close
                      </Button>
                      {operation === 'new' && <Button variant="primary" type="submit" >
                          Create Report
                      </Button>}
                      </Modal.Footer>
                    </Form>
                </Modal>

            </div>
        )

}

export default CreateReport;
