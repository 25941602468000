import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Form, Tabs,Tab,Button,Modal, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const urlCompany = "../components/companies.cfc?";
const urlUser = "../components/users.cfc?method=getUsersJSON";
const urlIndustry = "../components/industryFunctions.cfc?method=qryIndustryJSON";
const urlState = "../components/stateFunctions.cfc?method=qryStateJSON";

function CompanyEntry(props) {

  const mounted = useRef();
  const [showHide, setShowHide] = useState(false);
  const [users, setUsers] = useState(null);
  const [secondarycompanies, setSecondarycompanies] = useState(null);
  const [states, setStates] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState(null);

    useEffect(() => {
      if (!mounted.current) {
        // do componentDidMount logic
        const { company, operation } = props

        if(company && operation.toString().toLowerCase() !== 'new'){
            loadCompanyUsers(company.COMPANYID);
        }

        loadIndustries();
        loadStates();
        loadSecondaryCompanies();
        mounted.current = true;
      } else {
        // do componentDidUpdate logic

      }
    }, []);

  const handleSubmit = event => {
    event.preventDefault();
    const { company, operation } = props;

    // Delete Company
    if(operation.toString().toLowerCase() === 'delete'){

      const response = fetch(urlCompany + "method=deleteCompany&CompanyID="+company.COMPANYID)
      .then(response => {
        //here set a message to display confirmation
        setMessage("Company Deleted")
        setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
      });
    }
    // Insert New Company
    else if(operation.toString().toLowerCase() === 'new'){

      if (!event.target.checkValidity()){
        setValidated(true);
        return;
      }

      const data = new FormData(event.target);
      const response = fetch(urlCompany + "method=insertCompany", {
        method: 'POST',
        body: data,
      }).then(response => {
        //here set a message to display confirmation
        setMessage("New Company Added")
        setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
      });
    }
    // Update Existing Company
    else if(operation.toString().toLowerCase() === 'edit'){

      if (!event.target.checkValidity()){
        setValidated(true);
        return;
      }

      const data = new FormData(event.target);

      const response = fetch(urlCompany + "method=updateCompany", {
        method: 'POST',
        body: data,
      }).then(response => {
        //here set a message to display confirmation
        setMessage("Company Updated")
        setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        setTimeout(() => {  props.loadTable(); handleModalShowHide(); }, 2000);
      });
    }else{
      console.log("Unsupported operation '" + operation.toString().toLowerCase() + "'");
    }
  }

  const loadCompanyUsers = companyid => {
    axios.get(urlUser + '&Company_ID=' + companyid)
    .then(response => {
      setUsers(response.data)
    });
  }

  const loadIndustries = () => {
    axios.get(urlIndustry)
    .then(response => {
      setIndustries(response.data)
    });
  }

  const loadStates = () => {
    axios.get(urlState + '&ListStateActive=1')
    .then(response => {
      setStates(response.data)
    });
  }

  const loadSecondaryCompanies = () => {
    axios.get(urlCompany+"method=getCompanyJSON")
    .then(response => {
      setSecondarycompanies(response.data)
    });
  }

  const handleModalShowHide = () => {
    setShowHide(!showHide)
  }

  const { company, operation } = props;

  return(
    <div>
      {operation.toString().toLowerCase() === 'new' &&
      <Button variant="link" className="btn-new-object" onClick={() => handleModalShowHide()}>
        New Company
      </Button>}
      {operation === 'Edit' &&
      <Button variant="primary" onClick={() => handleModalShowHide()}>
        <FontAwesomeIcon icon={faEdit} />
        </Button>}
      {operation === 'Delete' &&
      <Button variant="danger" onClick={() => handleModalShowHide()}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>}

      <Modal show={showHide} size="lg" aria-labelledby="contained-modal-title-vcenter" animation centered >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {company &&
          <Form.Control name="COMPANYID" type="hidden" defaultValue={company.COMPANYID} />                      }
            <Modal.Header closeButton onClick={() => handleModalShowHide()}>
              <Modal.Title>{operation.toString().toLowerCase()==='new'?"New Company":operation + " " + company.COMPANYNAME + " - " + company.COMPANYCODE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Company / Organization Profile">
                {company && users &&
                  <Form.Group controlId="primaryadministrator">
                    <Form.Label>Primary Administrator *</Form.Label>
                    <Form.Control as="select" name="User_ID" required>
                    <option value="">Select a Primary Administrator</option>
                    {
                      users.map((user) =>
                        { return <option value={user.USERID} selected={company.USER_ID === user.USERID} >{user.USERFNAME}  {user.USERLNAME}</option> }
                      )
                    }
                    </Form.Control>
                  </Form.Group>}
                  <Form.Group controlId="companyname">
                    <Form.Label>Company/Org Name (D/B/A) *</Form.Label>
                    <Form.Control type="text" name="CompanyName" placeholder="Company Name" defaultValue={company?company.COMPANYNAME:''} required />
                  </Form.Group>
                  <Form.Group controlId="companylegalname">
                    <Form.Label>Legal Name (If different than above) </Form.Label>
                    <Form.Control type="text" name="LegalName" placeholder="Legal Name"  />
                  </Form.Group>
                  {industries &&
                  <Form.Group controlId="Industries">
                    <Form.Label>Industries *</Form.Label>
                    <Form.Control as="select" htmlSize={6} name="Industry" multiple required>
                    {
                      industries.map((industry) =>
                        { return <option value={industry.INDUSTRYID} selected={company && company.INDUSTRY!==null?company.INDUSTRY.toString().includes(industry.INDUSTRYID):false}>{industry.INDUSTRYNAME}</option> }
                      )
                    }
                    </Form.Control>
                  </Form.Group>}
                  <Form.Group controlId="companydescription">
                    <Form.Label>Company Description </Form.Label>
                    <Form.Control type="text" name="CompanyDescription" placeholder="Company Description" defaultValue={company?company.COMPANYDESCRIPTION:''}  />
                  </Form.Group>
                  <Form.Group controlId="companycode">
                    <Form.Label>Company Code *</Form.Label>
                    <Form.Control type="text" name="CompanyCode" placeholder="Company Code" defaultValue={company?company.COMPANYCODE:''} required />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group controlId="primarycontactname">
                        <Form.Label>Primary Contact Name *</Form.Label>
                        <Form.Control type="text" name="PrimaryContactName" placeholder="Primary Contact Name" defaultValue={company?company.PRIMARYCONTACTNAME:''} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="primarycontactphone">
                        <Form.Label>Primary Contact Phone *</Form.Label>
                        <Form.Control type="text" name="PrimaryContactPhone" placeholder="Primary Contact Phone" defaultValue={company?company.PRIMARYCONTACTPHONE:''} required />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="website">
                    <Form.Label>Web Site </Form.Label>
                    <Form.Control type="text" name="WebSite" placeholder="Web Site" defaultValue={company?company.WEBSITE:''} />
                  </Form.Group>
                </Tab>
                <Tab eventKey="profile" title="Corporate Address">
                  <Form.Group controlId="workphone">
                  <Form.Label>Corporate / Home Phone *</Form.Label>
                                  <Form.Control type="text" name="CompanyPhone" placeholder="Corporate / Home Phone" defaultValue={company?company.COMPANYPHONE:''} required />
                                </Form.Group>
                                <Form.Group controlId="workaddress1">
                                  <Form.Label>Corporate / Home Address *</Form.Label>
                                  <Form.Control type="text" name="CompanyAddress1" placeholder="Corporate / Home Address1" defaultValue={company?company.COMPANYADDRESS1:''} required />
                                </Form.Group>
                                <Form.Group controlId="workaddress2">
                                  <Form.Label>Corporate / Home Address 2</Form.Label>
                                  <Form.Control type="text" name="CompanyAddress2" placeholder="Corporate / Home Address2" defaultValue={company?company.COMPANYADDRESS2:''} />
                                </Form.Group>
                                <Form.Group controlId="workaddress3">
                                  <Form.Label>Corporate / Home Address 3</Form.Label>
                                  <Form.Control type="text" name="CompanyAddress3" placeholder="Corporate / Home Address3" defaultValue={company?company.COMPANYADDRESS3:''} />
                                </Form.Group>
                                <Form.Group controlId="workcity">
                                  <Form.Label>Corporate / Home City *</Form.Label>
                                  <Form.Control type="text" name="CompanyCity" placeholder="Corporate / Home City" defaultValue={company?company.COMPANYCITY:''} required />
                                </Form.Group>
                                <Form.Group controlId="workpostalcode">
                                  <Form.Label>Corporate / Home Postal Code *</Form.Label>
                                  <Form.Control type="text" name="CompanyPostalCode" placeholder="Corporate / Home Postal Code" defaultValue={company?company.COMPANYPOSTALCODE:''} />
                                </Form.Group>
                                {states &&
                                <Form.Group controlId="companystate">
                                  <Form.Label>Company State *</Form.Label>
                                  <Form.Control as="select" name="CompanyState">
                                  {
                                    states.map((stateitem) =>
                                      { return <option value={stateitem.LISTSTATEABB} selected={company && (company.COMPANYSTATE === stateitem.LISTSTATEABB || company.COMPANYSTATE === stateitem.LISTSTATEID) }>{stateitem.LISTSTATE}</option> }
                                    )
                                  }
                                  </Form.Control>
                                </Form.Group>}
                            </Tab>
                            <Tab eventKey="contact" title="Secondary Access">
                                Select the companies that will also have access to this clients projects
                                {secondarycompanies &&
                                <Form.Group controlId="SecondaryCompanies">
                                  <Form.Label>Company Name </Form.Label>
                                  <Form.Control as="select" multiple htmlSize={10} name="SecondaryCompanyID">
                                  {
                                    secondarycompanies.map((secondarycompany) =>
                                      { return <option value={secondarycompany.COMPANYID} selected={company && company.SECONDARYACCESS!==null?company.SECONDARYACCESS.toString().split(' ').includes(secondarycompany.COMPANYCODE):false}>{secondarycompany.COMPANYNAME} - {secondarycompany.COMPANYCODE}</option> }
                                    )
                                  }
                                  </Form.Control>
                                </Form.Group>}
                            </Tab>
                          </Tabs>
                          {message &&
                          <><Alert key='infoMessage001' variant='info'>
                            {message}
                          </Alert><br /></>}
                      </Modal.Body>
                      <Modal.Footer>
                      <Button variant="secondary" onClick={() => handleModalShowHide()}>
                          Close
                      </Button>
                      {operation === 'New' && <Button variant="primary" type="submit" >
                          Insert
                      </Button>}
                      {operation === 'Edit' && <Button variant="primary" type="submit" >
                          Save Changes
                      </Button>}
                      {operation === 'Delete' && <Button variant="danger" type="submit" >
                          Delete
                      </Button>}
                      </Modal.Footer>
                    </Form>
                </Modal>

            </div>
        )
}

export default CompanyEntry;
