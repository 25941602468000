import axios from 'axios';
// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

//return the current company id for view permissions
export const getCurrentCompanyUI = () => {
  return sessionStorage.getItem('currentCompanyID') || null;
}

//set the current company id  for view permissions
export const setCurrentCompanyUI = (currentCompanyID) => {
  sessionStorage.setItem('currentCompanyID', currentCompanyID);
}

// return the userID from the session storage
export const getUserID = () => {
  return sessionStorage.getItem('userID') || null;
}

// return the userID from the session storage
export const setUserID = (userID) => {
  sessionStorage.setItem('userID', userID);
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// return the token from the session storage
export const getIsAdmin = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr).ISADMIN;
  else return null;
}

// return the token from the session storage
export const tokenIsValid = () => {
  return async dispatch => {
    function onSuccess(success) {
      dispatch(true);
      return success;
    }
    function onError(error) {
      dispatch(false);
      return error;
    }
    try {
      const success = await axios.get(`../components/appsession.cfc?method=verifyToken&token=${sessionStorage.getItem('token')}`);
      return onSuccess(success);
    } catch (error) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      return onError(error);
    }
  }
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('currentCompanyID');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
  if(getCurrentCompanyUI() === undefined){
    setCurrentCompanyUI(user.COMPANYID)
    console.log("Setting Current Company ID for this session: " + user.COMPANYID)
  }else{
    console.log("Current Company ID already is: " + getCurrentCompanyUI())
  }
  console.log("Setting User ID for this session: " + user.USERID)
  setUserID(user.USERID)
}
