import React, { useState } from 'react';
import { Form,Button} from 'react-bootstrap'


function SidebarUsers(props){
  const userfname = useFormInput('');
  const userlname = useFormInput('');
  const handleSubmit = event => {
    event.preventDefault();
    props.history.replace(`/admin/users?searchFName=`+encodeURIComponent(userfname.value)+'&searchLName='+encodeURIComponent(userlname.value));
  }

  return(
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="userfname">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" name="userfname" placeholder="First Name" {...userfname} />
      </Form.Group>
      <Form.Group controlId="userlname">
        <Form.Label>Last Name</Form.Label>
        <Form.Control type="text" name="userlname" placeholder="Last Name" {...userlname} />
      </Form.Group>
      <Button variant="primary" type="submit" >
          Search
      </Button>
    </Form>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default SidebarUsers;
