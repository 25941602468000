import React, { useState } from 'react';
import { Form,Button} from 'react-bootstrap'


function SidebarCompanies(props){
  const companyname = useFormInput('');
  const handleSubmit = event => {
    event.preventDefault();
    props.history.push('/admin/companies?searchCompanyName='+encodeURIComponent(companyname.value));
  }

  return(
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="companyname">
        <Form.Label>Company/Org Name (D/B/A) *</Form.Label>
        <Form.Control type="text" name="CompanyName" placeholder="Company Name" {...companyname} required />
      </Form.Group>
      <Button variant="primary" type="submit" >
          Search
      </Button>
    </Form>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default SidebarCompanies;
