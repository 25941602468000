import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";


function LoginReset(props) {
  const querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [userId, setUserID] = useState(null);

  const userPassword1 = useFormInput('');
  const userPassword2 = useFormInput('');

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      checkValidResetToken(querystr);
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
    }
  }, []);

  const checkValidResetToken = query => {
    var getUrl = "../components/appsession.cfc?method=qryUserIsResetValid";
    if(query && query.get("reset")){
      console.log(query.get("reset"));
      getUrl = getUrl + "&UserResetSecretValue="+query.get("reset")
    }else{
      setError("There is no token in request");
    }
    if(query && query.get("u")){
      console.log(query.get("u"));
      getUrl = getUrl + "&UserName="+query.get("u")
    }else{
      setError("There is no username in request");
    }

    axios.get(getUrl)
    .then(response => {
      setResult(response.data);
      if(response.data && response.data.success){
        setUserID(response.data.userid)
      }
    })
    .catch(error => {
      setError('There has been a problem with your fetch operation: ', error.message);
    });
  }

  const handleSubmitPasswordChange = () =>  {

    setError(null);
    setLoading(true);

    var url = '../components/appsession.cfc?method=resetPassword';

    if(querystr && querystr.get("reset")){
      console.log(querystr.get("reset"));
      url = url + "&reset=" + querystr.get("reset");
    }
    if(querystr && querystr.get("u")){
      console.log(querystr.get("u"));
      url = url + "&u=" + querystr.get("u");
    }
    if(userId){
      console.log(userId);
      url = url + "&userId=" +userId;
    }

    url = url + "&userPassword1=" + encodeURIComponent(userPassword1.value);
    url = url + "&userPassword2=" + encodeURIComponent(userPassword2.value);

    console.log(url);

    axios.get(url)
    .then(response => {
      setLoading(false);
      console.log(response.data)
      setResult(response.data);
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError("Unauthorized");
      else setError("Something went wrong. Please try again later.");
    });
  }

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <img src="logo.png" id="icon" alt="User Icon" />
        </div>

        {(result && result.error && result.invalidtoken)?
          <><small style={{ color: 'red' }}>{result.message}</small><br /></>:
        <Form>
          <Form.Group controlId="UserPassword1">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="UserPassword1" {...userPassword1} className="fadeIn second" />
          </Form.Group>
          <Form.Group controlId="UserPassword2">
            <Form.Label>Password Retype</Form.Label>
            <Form.Control type="password" name="UserPassword2" {...userPassword2} className="fadeIn third" />
          </Form.Group>
          {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
          {(result && result.error && result.messages)?
            result.messages.map((errormessage) =>
              { return <><small style={{ color: 'red' }}>{errormessage}</small><br /></>}
            ):<><br /></>
          }
          {(result && result.success) && <><small style={{ color: 'green' }}>{result.message}</small><br /></>}<br />
          <Button variant="primary" className="fadeIn fourth" type="button" onClick={handleSubmitPasswordChange} disabled={loading}>
            {loading ? 'Loading...' : 'Change'}
          </Button>
        </Form>
        }
        <br />
        <div id="formFooter">
          <Link className="link" to="/login">Go to Login Page</Link>
        </div>
      </div>
    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default withRouter(LoginReset);
