import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import './App.css';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import UserRoute from './utils/UserRoute';
import axios from 'axios';

import Login from './components/Login/Login';
import LoginReset from './components/Login/LoginReset';
import Dashboard from './components/Dashboard/Dashboard';
import CompanyList from './components/Companies/CompanyList';
import UserList from './components/Users/UserList';
import ResourceList from './components/Resources/ResourceList';
import ReportsAccessList from './components/Reports/ReportsAccessList';
import LabUpdate from './components/Others/LabUpdate';
import RegLimitsUpdate from './components/Others/RegLimitsUpdate';
import ViewRawData from './components/Others/ViewRawData';

import CompanyProfile from './components/EndUserInterface/CompanyProfile';
import CompanyUsers from './components/EndUserInterface/CompanyUsers';
import CompanyResources from './components/EndUserInterface/ResourceList';
import CompanyProjects from './components/EndUserInterface/CompanyProjects';

import { getToken, getUser, removeUserSession, setUserSession } from './utils/Common';
import uuid from 'react-uuid'

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  const mounted = useRef();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!mounted.current) {
      const token = getToken();
      if (!token) {
        return;
      }

      axios.get(`../components/appsession.cfc?method=verifyToken&token=${token}`)
      .then(response => {
        setAuthLoading(false);
        if(response && response.data && response.data.USERGROUPID === 1){
          setUserSession(response.data.TOKEN, response.data);
        }
        if(response && response.data && (response.data.USERGROUPID === 2 || response.data.USERGROUPID === 3)){
          setUserSession(response.data.TOKEN, response.data);
        }
      }).catch(error => {
        removeUserSession();
        setAuthLoading(false);
      });
      mounted.current = true;
    } else {
      const token = getToken();
      if (!token) {
        return;
      }

      axios.get(`../components/appsession.cfc?method=verifyToken&token=${token}`)
      .then(response => {
        setAuthLoading(false);
        if(response && response.data && response.data.USERGROUPID === 1){
          setUserSession(response.data.TOKEN, response.data);
        }
        if(response && response.data && (response.data.USERGROUPID === 2 || response.data.USERGROUPID === 3)){
          setUserSession(response.data[0].TOKEN, response.data[0]);
        }
      }).catch(error => {
        removeUserSession();
        setAuthLoading(false);
      });
    }
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>
  }

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Switch>

          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/login-reset" component={LoginReset} />

          <UserRoute key={uuid()} path="/user/resources" component={CompanyResources} />
          <UserRoute key={uuid()} path="/user/companyprofile" component={CompanyProfile} />
          <UserRoute key={uuid()} path="/user/companyusers" component={CompanyUsers} />

          <UserRoute key={uuid()} path="/user/home" component={CompanyProjects} />
          <UserRoute key={uuid()} path="/user" component={CompanyProjects} />

          <PrivateRoute key={uuid()} path="/admin/companies/:companyparamname/:companyparamvalue" component={CompanyList} />
          <PrivateRoute key={uuid()} exact path="/admin/companies" component={CompanyList} />

          <PrivateRoute key={uuid()} exact path="/admin/users" component={UserList}/>
          <PrivateRoute key={uuid()} path="/admin/users/:userparamname/:userparamvalue" component={UserList}/>

          <PrivateRoute key={uuid()} exact path="/admin/resources" component={ResourceList} />
          <PrivateRoute key={uuid()} path="/admin/resources/:resourceparamname/:resourceparamvalue" component={ResourceList} />

          <PrivateRoute key={uuid()} exact path="/admin/reportsaccesslist" component={ReportsAccessList} />

          <PrivateRoute key={uuid()} exact path="/admin/labupdate" component={LabUpdate} />
          <PrivateRoute key={uuid()} path="/admin/labupdate/:labupdateparamname/:labupdateparamvalue" component={LabUpdate} />

          <PrivateRoute key={uuid()} path="/admin/reglimitsupdate" component={RegLimitsUpdate} />

          <PrivateRoute key={uuid()} path="/admin/viewrawdata" component={ViewRawData} />

          <PrivateRoute key={uuid()} path="/admin/dashboard" component={Dashboard} />

          <PrivateRoute key={uuid()} exact path="/admin/" component={Dashboard} />
          <PublicRoute key={uuid()} exact path="/" component={Login} />

          <PublicRoute render={() => <h1>404: page not found</h1>} />

        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
