import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap'
import { useLocation, withRouter } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";


function ViewRawData(props) {
  const [loading, setLoading] = useState(false);
  const limsNum = useFormInput('');
  const [error, setError] = useState(null);
  const [resultsLimsNumber, setResultsLimsNumber] = useState(null);
  const [resultsLastDataUpdates, setResultsLastDataUpdates] = useState(null);

  const handleSubmitLimsNumber = event =>  {

    event.preventDefault();
    setResultsLimsNumber([])

    setError(null);
    setLoading(true);

    var url = `../components/utils.cfc?method=getAllDataForTestViaLimsNumber`;
    const data = new FormData(event.target);

    const response = fetch(url, {
      method: 'POST',
      body: data,
    }).then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      setResultsLimsNumber(data)
    }).catch(error => {
      setError({ errorMessage: error.toString() });
      console.log('There was an error!', error);
    });
    setLoading(false);
  }

  const handleSubmitLastDataUpdates = event =>  {

    event.preventDefault();
    setResultsLastDataUpdates([])

    setError(null);
    setLoading(true);

    var url = '../components/utils.cfc?method=getLastDataUpdates';

    const response = fetch(url, {
      method: 'GET'
    }).then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      setResultsLastDataUpdates(data)
    }).catch(error => {
      setError({ errorMessage: error.toString() });
      console.log('There was an error!', error);
    });
    setLoading(false);
  }

  return (
    <div className="container">
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }} >
        <Form noValidate onSubmit={handleSubmitLimsNumber}>
          Use the form below to get all data for test via the Lims number or test group id.
          <Form.Group controlId="LimsNum">
            <Form.Label>Lims Num #</Form.Label>
            <Form.Control type="text" name="LimsNum" {...limsNum}  />
          </Form.Group>
          <Button   variant="link" type="submit" >
            {loading ? 'Loading...' : 'Submit'}
            </Button>
        </Form>
          {loading && <>Loading...</>}
          {(resultsLimsNumber && resultsLimsNumber.success) &&
            <>
            All Data for lims number {limsNum.value}:
            <br />
            <JsonToTable json={resultsLimsNumber.records} />
          </>}
          {(resultsLimsNumber && resultsLimsNumber.error) &&
            <>
            <br/>
            <strong>Error:{resultsLimsNumber.message}</strong> <br/>
          </>}
        <Form noValidate onSubmit={handleSubmitLastDataUpdates}>
          <Button   variant="link" type="submit" >
            {loading ? 'Loading...' : 'View last data updates.'}
          </Button>
        </Form>
          {loading && <>Loading...</>}
          {(resultsLastDataUpdates && resultsLastDataUpdates.success) &&
            <>
            Last data updates:
            <br />
            <JsonToTable json={resultsLastDataUpdates.records} />
          </>}
          {(resultsLastDataUpdates && resultsLastDataUpdates.error) &&
            <>
            <br/>
            <br/>
            <strong>Error:{resultsLastDataUpdates.message}</strong> <br/>
          </>}
      </div>
    </div>
  );
}
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
export default withRouter(ViewRawData);
