import React from 'react'
import { Form,Button,Accordion,Card } from 'react-bootstrap'
import SidebarCompanies from './SidebarCompanies'
import SidebarUsers from './SidebarUsers'
import SidebarResources from './SidebarResources'


function Sidebar(props){
  return(
    <>
      <Card>
        <Card.Body>
          <Card.Title>Find Companies</Card.Title>
          <Card.Text>
            <SidebarCompanies {...props} />
          </Card.Text>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Find Users</Card.Title>
          <Card.Text>
            <SidebarUsers {...props} />
          </Card.Text>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Find Files</Card.Title>
          <Card.Text>
            <SidebarResources {...props} />
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}

export default Sidebar;
