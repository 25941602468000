import React from 'react'
import axios from 'axios'
import { Form,Button,Modal,Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const url = "../components/companies.cfc?";

class CompanyEnableDisableModal extends React.Component{

    constructor(){
        super();
        this.state = {
            showHide : false,
            dataReady : true
        }
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }

    enableDisableCompany = event => {
      event.preventDefault();
      this.setState({ dataReady: !this.state.dataReady });
      const { company, operation } = this.props;
      const enableDisable = (operation === 'Enable'?0:1);
      //Call method to enable/disable a company and its users
      axios.get(url+'method=disableEnableCompanyUsers&CompanyID=' + company.COMPANYID +
       '&DisableCompany=' + enableDisable +
        '&DisableUser=' + enableDisable)
      .then(response => {
        this.setState({ dataReady: !this.state.dataReady });
        this.props.loadTable();
        this.handleModalShowHide();
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
      });

    }

    render(){
      const { company, operation } = this.props;

        return(
            <div>
                {operation === 'Enable' && <Button variant="success" onClick={() => this.handleModalShowHide()}>
                    {this.state.dataReady?<FontAwesomeIcon icon={faCheck} />:<Spinner as="span" animation="border" size="sm" />}
                </Button>}
                {operation === 'Disable' && <Button variant="warning" onClick={() => this.handleModalShowHide()}>
                    {this.state.dataReady?<FontAwesomeIcon icon={faTimes} />:<Spinner as="span" animation="border" size="sm" />}
                </Button>}

                <Modal show={this.state.showHide}>
                    <Form onSubmit={this.enableDisableCompany}>
                      <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                      <Modal.Title>{operation} {company.COMPANYNAME} {company.COMPANYID}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to {operation.toString().toLowerCase()} {company.COMPANYNAME} - {company.COMPANYCODE}?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleModalShowHide()}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            {operation}
                        </Button>
                      </Modal.Footer>
                    </Form>
                </Modal>

            </div>
        )
    }

}

export default CompanyEnableDisableModal;
