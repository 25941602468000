import React, { useState } from 'react';
import axios from 'axios';
import { setUserSession } from '../../utils/Common';
import { Form, Button } from 'react-bootstrap';
import ForgotPasswordForm from './ForgotPasswordForm';
import './login.css';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const username = useFormInput('');
  const userpassword = useFormInput('');
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleLogin = event =>  {
    event.preventDefault();



    setForgotPassword(false);
    setError(null);
    setLoading(true);

    var url = '../components/appsession.cfc?';

    axios.get(url + 'method=signIn&UserName=' + encodeURIComponent(username.value) + '&UserPassword=' + encodeURIComponent(userpassword.value))
    .then(response => {
      setLoading(false);
      if(response && response.data && (response.data[0].USERGROUPID === 1 || response.data[0].USERGROUPID === 2)){
        setUserSession(response.data[0].TOKEN, response.data[0]);
        if(props.location && props.location.state && props.location.state.originalPath){
            console.log("originalPath is: " + props.location.state.originalPath)
          props.history.push(props.location.state.originalPath);
        }else{
          props.history.push('/admin/dashboard');
        }
      }
      if(response && response.data && response.data[0].USERGROUPID === 3){
        setUserSession(response.data[0].TOKEN, response.data[0]);
        if(props.location && props.location.state && props.location.state.originalPath){
            console.log("originalPath is: " + props.location.state.originalPath)
          props.history.push(props.location.state.originalPath);
        }else{
          props.history.push('/user/home');
        }
      }
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError("Username or Password is wrong");
      else setError("Something went wrong. Please try again later.");
    });
  }

  const handleForgotPassword = event => {
    setForgotPassword(!forgotPassword);
  }

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">

        <div className="fadeIn first">
          <img src="logo.png" id="icon" alt="User Icon" />
        </div>

        <Form onSubmit={handleLogin}>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" name="username" className="fadeIn second" {...username} />
          </Form.Group>
          <Form.Group controlId="userpassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="userpassword" className="fadeIn third" {...userpassword} />
          </Form.Group>
          {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
          <Button variant="primary" className="fadeIn fourth" type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Login'}
          </Button>
        </Form>
        <br />
        <div id="formFooter">
          <Button className="underlineHover" onClick={handleForgotPassword} variant="link" name="forgotPassword" type="button" >
            Forgot Password?
          </Button>
          {forgotPassword &&
            <><ForgotPasswordForm {...props} /></>
          }
        </div>
      </div>
    </div>

  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;
