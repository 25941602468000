import React, { useState, useEffect, useRef } from 'react';
import { Route, Redirect, NavLink, useLocation } from 'react-router-dom';
import { getToken, getUser, tokenIsValid, removeUserSession, setUserSession } from './Common';
import Sidebar from '../components/Sidebar/Sidebar';
import Logout from '../components/Login/Logout';
import axios from 'axios';
import uuid from 'react-uuid'

// handle the private routes
function PrivateRoute({ component: Component, path: currentPath,  ...rest }) {
  const [authFails, setAuthFails] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const mounted = useRef();
  const [error, setError] = useState(null);
  const { location } = rest;
  const search = window.location.search;

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic

      console.log("currentPath is: " + location.pathname + search)

      const token = getToken();
      if (!token) {
        return;
      }

      axios.get(`../components/appsession.cfc?method=verifyToken&token=${token}`)
      .then(response => {
        setAuthLoading(false);
        if(response.data.USERGROUPID === 1 || response.data.USERGROUPID === 2){
            setAuthFails(false);
          setUserSession(response.data.TOKEN, response.data);
        }else{
          setError("Your account does not have administrative access.");
            removeUserSession();
            setAuthFails(true);
          
            
        }

      }).catch(error => {
        removeUserSession();
        setAuthLoading(false);
      });
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      const token = getToken();
      console.log(token);
      if (!token) {
        return;
      }

      axios.get(`../components/appsession.cfc?method=verifyToken&token=${token}`)
      .then(response => {
        setAuthLoading(false);
        if(response.data.USERGROUPID === 1 || response.data.USERGROUPID === 2){
            setAuthFails(false);
          setUserSession(response.data.TOKEN, response.data);
        }else{
          setError("Your account does not have administrative access.");
            removeUserSession();
            setAuthFails(true);                   
        }
      }).catch(error => {
        removeUserSession();
          setAuthFails(true);  
          setAuthLoading(false);
      });
    }
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Loading...</div>
  }
    
    if (authFails){
        return <Redirect to='/login'/>
    }


  return (
    <Route
    {...rest}
    render={ (props) => getToken()?
      <div id="root">
        <div className="d-flex" id="wrapper">
          <div className="bg-light border-right" id="sidebar-wrapper">
            <div className="sidebar-heading">
              <a href="https://extranet.absoluteresourceassociates.com/" target="_self">
                <img src="../logo.png"/>
              </a>
            </div>
            <div className="list-group list-group-flush">
              <div className="sidebar-cards">
                <Sidebar {...props} />
              </div>
            </div>
          </div>
          <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/dashboard">Dashboard</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/companies">Companies</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/users">Users</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/resources">Files</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/reportsaccesslist">Reports Access List</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/labupdate">Lab Update</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/reglimitsupdate">Reg Limits Update</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin/viewrawdata">View Raw Data</NavLink>
                  </li>
                  <li className="nav-item">
                    <Logout {...props} />
                  </li>
                </ul>
              </div>
          </nav>
          <div className="container-fluid">
            <Component key={uuid()} {...props} />
          </div>
        </div>
      </div>
    </div>
      : <Redirect to={{ pathname: '/login', state: {originalPath: location.pathname + search} }} />}
    />
  )
}

export default PrivateRoute;
