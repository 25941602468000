import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Form, Button, Alert } from 'react-bootstrap'

const url = "../components/reportAccess.cfc?";

function ReportTypesSelect (props){

  const mounted = useRef();
  const [reporttypes, setReporttypes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);

    useEffect(() => {
        if (!mounted.current) {
          // do componentDidMount logic

          loadReportTypes();
          mounted.current = true;
        } else {
          // do componentDidUpdate logic

        }
      }, []);

    const loadReportTypes = () => {
      axios.get(url + "method=getReportTypesJSON")
      .then(response => {
        setReporttypes(response.data)
      });
    }

    const handleSubmit = event => {
      event.preventDefault();

      setLoading(true)

      const data = new FormData(event.target);

      const response = fetch(url + "method=updateReportsAccessListForCompany", {
        method: 'POST',
        body: data,
      }).then(response => {
        console.log('Report Permission Saved: ', response.data);
        setMessage('Report Permission Saved.')
        setTimeout(() => {  setMessage(null); }, 2000);
      }).catch(error => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        setMessage('There has been a problem with your fetch operation: ' + error.message)
        setTimeout(() => {  setMessage(null); }, 2000);
      });

      setLoading(false)
    }


      const { company } = props;

        return(
          <Form onSubmit={handleSubmit}>
            <Form.Control name="COMPANYID" type="hidden" defaultValue={company.COMPANYID} />
            {reporttypes &&
              <Form.Group>
              {
                reporttypes.map((currentReportType) =>
                  { return <Form.Check
                              key={currentReportType.REPORTTYPEID}
                              type="checkbox"
                              defaultValue={currentReportType.REPORTTYPEID}
                              name="ReportID"
                              label={currentReportType.REPORTNAME}
                              defaultChecked={company.REPORTACCESSLIST===null?false:company.REPORTACCESSLIST.toString().split(' ').includes(currentReportType.REPORTTYPEID.toString())}/> }
                )
              }
              {message &&
              <><Alert key='infoMessage001' variant='info'>
                {message}
              </Alert><br /></>}
            </Form.Group>}
            <Button variant="primary" type="submit" >
                {loading ? 'Saving...' : 'Save'}
            </Button>
          </Form>
        )

}

export default ReportTypesSelect;
