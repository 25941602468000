import React, { Component } from 'react'
import ReportTypesSelect from './ReportTypesSelect';
import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";
import { removeUserSession } from '../../utils/Common';


const url = "../components/reportAccess.cfc?method=getAllCompaniesReportAccessList";

class ReportsAccessList extends Component {

  constructor(props) {
    super(props);
    this.searchParams = new URLSearchParams(this.props.location.search);
    this.state = {
        currentURL : "",
        loading: false,
        records: [],
        columns: [{
            dataField: 'COMPANYID',
            text: 'ID',
            sort: true,
            hidden: true
          },
          {
            dataField: 'COMPANYCODE',
            text: 'Company Code',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'COMPANYNAME',
            text: 'Company Name',
            sort: true,
            filter: textFilter()
          },
          {
            dataField: 'REPORTACCESS',
            text: 'Report Access',
            formatter: (cell, row, rowIndex) => (
              <div ml-auto>
                <ReportTypesSelect company = {row} />
              </div>
            )
          }]
        }
    this.loadTable = this.loadTable.bind(this);
  }

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  useQuery() {
    return this.searchParams;
  }

  loadTable() {
        const { match, location } = this.props;
        this.setState({
          currentURL: location.pathname
        });
        this.setState({
          loading: true
        });

        axios.get(
          url)
        .then(response => {
          this.setState({
            records: response.data
          });
        })
        .catch(error => {
          this.setState({
            records:[]
          });
          console.log('There has been a problem with your fetch operation: ', error.message);
          if (error.response.status === 401){
            removeUserSession();
            this.props.history.push('/login');
          }
        });
        this.setState(() => ({ records: [] }));
        this.setState({
          loading: false
        });
  }

  componentDidMount() {
    this.loadTable()
  }


  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadTable()
    }
  }




  render() {
    // ...
    const LoadingIndication = () => (
      <Spinner animation="border" />
    );
    const NoDataIndication = () => (
      <>There are no data to display.</>
    );

    const options = {

                          sizePerPageList: [ {
                            text: '10', value: 10
                          }, {
                            text: '50', value: 50
                          }, {
                            text: 'All', value: this.state.records.length
                          } ]

                        };

    return (
      <div className="container">
        <div className="row hdr">
          <div className="col-sm-12">
          </div>
        </div>
        <div  style={{ marginTop: 20 }}>
        <BootstrapTable striped hover
          keyField='ResourceID'
          columns={ this.state.columns }
          data={ this.state.records }
          pagination={ paginationFactory(options) }
          filter={ filterFactory() }
          noDataIndication={this.loading?() => <LoadingIndication />:() => <NoDataIndication />}>
        </BootstrapTable>
        </div>
      </div>
    )
  }

}

export default withRouter(ReportsAccessList);
