import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Tabs,Tab,Button } from 'react-bootstrap'
import { useLocation, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import DashboardFileUpload from "./DashboardFileUpload.js"

import "react-datepicker/dist/react-datepicker.css";

function Dashboard(props) {
  const [loading, setLoading] = useState(false);
  const labnum = useFormInput('');
  const [companies, setCompanies] = useState(null);
  const [samples, setSamples] = useState(null);
  const [labnumbers, setLabnumbers] = useState(null);
  const [reporttypes, setReporttypes] = useState(null);
  const [error, setError] = useState(null);
  const companySelected = React.createRef();
  const companyDateSearchSelected = React.createRef();
  const labNumSelected = React.createRef();
  const frmSubmitSearchFiles = useFormInput(null);
  const frmCreateReport = useFormInput(null);
  const [results, setResults] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {

    axios.get(`../components/companies.cfc?method=getCompanyForBindingJSON`)
    .then(response => {
      setCompanies(response.data);

      axios.get(`../components/samples.cfc?method=buildSampleDataSetJSON&CompanyCode=${response.data[0].COMPANYCODE}`)
      .then(response => {
        setSamples(response.data);

        axios.get(`../components/samples.cfc?method=getSampleByProjectNameJSON&LabNum=${response.data[0].LABNUMTRIMMED}`)
        .then(response => {
          setLabnumbers(response.data);
        }).catch(error => {

        });
      }).catch(error => {

      });
    }).catch(error => {

    });

    axios.get(`../components/reportAccess.cfc?method=getReportTypesJSON`)
    .then(response => {
      setReporttypes(response.data);

    }).catch(error => {

    });

    setLoading(false);

  }, []);

  if (!companies && !reporttypes) {
    return <div className="content">Loading...</div>
  }

  function fetchSamples(companyCode){
    axios.get(`../components/samples.cfc?method=buildSampleDataSetJSON&CompanyCode=${companyCode}`)
    .then(response => {
      setSamples(response.data);
      axios.get(`../components/samples.cfc?method=getSampleByProjectNameJSON&LabNum=${response.data[0].LABNUMTRIMMED}`)
      .then(response => {
        setLabnumbers(response.data);
      }).catch(error => {

      });
    }).catch(error => {

    });
  }

  function fetchLabnumbers(labNum){
    axios.get(`../components/samples.cfc?method=getSampleByProjectNameJSON&LabNum=${labNum}`)
    .then(response => {
      setLabnumbers(response.data);
    }).catch(error => {

    });
  }

  function handleChangeCompany(e) {
    console.log(e.target.value);
    fetchSamples(e.target.value)
  }

  function fetchLabnumbers(labNum){
    axios.get(`../components/samples.cfc?method=getSampleByProjectNameJSON&LabNum=${labNum}`)
    .then(response => {
      setLabnumbers(response.data);
    }).catch(error => {

    });
  }

  function handleChangeSample(e) {
    console.log(e.target.value);
    fetchLabnumbers(e.target.value);
  }

  const handleSubmitSearchByProjectName = event =>  {

    event.preventDefault();

    setError(null);
    setLoading(true);

    var url = '../components/utils.cfc?method=createReports';
    const data = new FormData(event.target);
    data.append('frmCreateReport','true');

    const response = fetch(url, {
      method: 'POST',
      body: data,
    }).then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      setResults(data)
    }).catch(error => {
      this.setState({ errorMessage: error.toString() });
      console.error('There was an error!', error);
    });
    setLoading(false);
  }

  const handleSearchLabNum = event =>  {
    event.preventDefault();
    console.log('Click on reports');
    console.log('labNumSelected ' + labNumSelected.current.value);
    props.history.push('/admin/resources/searchLabNum/'+encodeURIComponent(labNumSelected.current.value));
  }

  const handleSubmitSearchProjectsAndFilesByDate = event =>  {

    event.preventDefault();

    setError(null);
    setLoading(true);

    var url = '../components/utils.cfc?method=createReports';
    const data = new FormData(event.target);

    const response = fetch(url, {
      method: 'POST',
      body: data,
    }).then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      setResults(data)
    }).catch(error => {
      setError({ errorMessage: error.toString() });
      console.error('There was an error!', error);
    });
    setLoading(false);
  }

  return (
    <div className="container">
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }} >
        <Tabs defaultActiveKey="project" id="uncontrolled-tab-example">
          <Tab eventKey="project" title="Search By Project Name">
            <Form noValidate onSubmit={handleSubmitSearchByProjectName}>
              <Form.Group inline controlId="companyName">
                <Form.Label >Company Name Change</Form.Label>
                <Form.Control as="select" name="companyName" onChange={handleChangeCompany} ref={companySelected} required>
                {companies?
                  companies.map((company) =>
                    { return <option value={company.COMPANYCODE}>{company.COMPANYNAME}</option> }
                  ):<option>Loading...</option>
                }
                </Form.Control>
              </Form.Group>
              <Form.Group inline  controlId="projectName">
                <Form.Label >Project Name - Job # - Date Collected 	</Form.Label>
                <Form.Control as="select" name="projectName" ref={labNumSelected} onChange={handleChangeSample} required>
                {samples?
                  samples.map((sample) =>
                    { return <option value={sample.LABNUMTRIMMED}>{sample.LABPROJDATE}</option> }
                  ):<option>Loading...</option>
                }
                </Form.Control>
              </Form.Group>
              <Form.Group inline controlId="projectLabNum">
                <Form.Label >Samples Included in Report</Form.Label>
                <Form.Control as="select" name="projectLabNum" required>
                {labnumbers?
                  labnumbers.map((labnum) =>
                    { return <option value={labnum.LABNUM}>{labnum.LABNUMSAMPLEID}</option> }
                  ):<option>Loading...</option>
                }
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="reportType" >
                <Form.Label >Pick Report Type </Form.Label>
                {reporttypes?
                  reporttypes.map((currentReportType) =>
                    { return <Form.Check key={currentReportType.REPORTTYPEID} type="radio" defaultValue={currentReportType.REPORTTYPEID} name="reportType" label={currentReportType.REPORTNAME}/> }
                  ):<>Loading...</>
                }
              </Form.Group>
              <Button   variant="link" name="frmSubmitSearchFiles" {...frmSubmitSearchFiles} type="button" onClick={handleSearchLabNum} >
                  {loading ? 'Loading...' : 'Display Downloadable Files'}
              </Button>
              <Button   variant="link" {...frmCreateReport} type="submit" >
                  {loading ? 'Loading...' : 'Create Reports'}
              </Button>
            </Form>
            {(results && results.success) &&
              <>
              <br />
              <br />
              <strong>Report:{results.report} - Job #: {results.labnumber} </strong> <br/>
              <strong>Total Records: {results.total_records_in_report} in report</strong><br/>
              {results.total_records_in_report>0 && <><a href={results.report_to_download_link}>Download {results.report_to_download_name}</a><br/></>}
              </>}
              {(results && results.error) &&
                <>
                <br/>
                <br/>
                <strong>Error:{results.message}</strong> <br/>
                </>}
          </Tab>

          <Tab eventKey="fileupload" title="File Upload">
            <DashboardFileUpload {...props} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default withRouter(Dashboard);
