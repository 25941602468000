import React, { useState, useEffect, useRef } from 'react';
import { Route, Redirect, NavLink } from 'react-router-dom';
import { getToken, getUser, tokenIsValid, removeUserSession, setUserSession } from './Common';
import Sidebar from '../components/EndUserInterface/Sidebar';
import Logout from '../components/Login/Logout';
import axios from 'axios';
import uuid from 'react-uuid'
import { setCurrentCompanyUI, getCurrentCompanyUI } from '../utils/Common';

// handle the private routes
function UserRoute({ component: Component, path: currentPath, ...rest }) {
  const [authFails, setAuthFails] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const mounted = useRef();
  const [error, setError] = useState(null);
  const { location } = rest;
  const search = window.location.search;
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic

      console.log("currentPath is: " + location.pathname + search)

      const token = getToken();
      if (!token) {
        return;
      }

      console.log("UserRoute: about to verify token " + token);          

      axios.get(`../components/appsession.cfc?method=verifyToken&token=${token}`)
        .then(response => {
          console.log("UserRoute: LOGGEDIN " + response.data.LOGGEDIN);          
          setAuthLoading(false);          
          setUserSession(response.data.TOKEN, response.data);
          setCompanyId(response.data.COMPANYID)
          if (getCurrentCompanyUI() === null) {
            setCurrentCompanyUI(response.data.COMPANYID)
          }
        }).catch(error => {
          console.log("UserRoute: Error on request " + error);  
          removeUserSession();
          setAuthFails(true);
          setAuthLoading(false);
        });
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      const token = getToken();
      console.log(token);
      if (!token) {
        return;
      }

      axios.get(`../components/appsession.cfc?method=verifyToken&token=${token}`)
        .then(response => {
          setAuthLoading(false);
          setUserSession(response.data.TOKEN, response.data);
          setCompanyId(response.data.COMPANYID)
        }).catch(error => {
          removeUserSession();
          setAuthFails(true);
          setAuthLoading(false);
        });
    }
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Loading...</div>
  }

  if (authFails) {
    return <Redirect to='/login' />
  }

  return (
    <Route
      {...rest}
      render={(props) => getToken() ?
        <div id="root">
          <div className="d-flex" id="wrapper">
            <div className="bg-light border-right" id="sidebar-wrapper">
              <div className="sidebar-heading">
                <a href="https://extranet.absoluteresourceassociates.com/" target="_self">
                  <img src="../logo.png" />
                </a>
              </div>
              <div className="list-group list-group-flush">
                <div className="sidebar-cards">
                  <Sidebar key={uuid()} companyid={companyId} {...props} />
                </div>
              </div>
            </div>
            <div id="page-content-wrapper">
              <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/user/home">Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/user/resources">Files</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/user/companyprofile">Company Profile</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/user/companyusers">Users</NavLink>
                    </li>
                    <li className="nav-item">
                      <Logout {...props} />
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="container-fluid">
                <Component key={uuid()} companyid={companyId} {...props} />
              </div>
            </div>
          </div>
        </div>
        : <Redirect to={{ pathname: '/login', state: { originalPath: location.pathname + search } }} />}
    />
  )
}

export default UserRoute;
