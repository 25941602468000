import React, { useState, useEffect, useRef } from 'react';
import UserEntry from './UserEntry';
import { getUserID, removeUserSession } from '../../utils/Common';

import axios from 'axios';
import { Spinner } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from "react-router-dom";


function CompanyUsers(props) {
  const url = "../components/users.cfc?method=getUsersJSON";
  const querystr = new URLSearchParams(useLocation().search);
  const mounted = useRef();
  const [currentURL, setCurrentURL] = useState(null);
  const [userID, setUserID] = useState(null);
  const [myparam, setMyParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([{
                                            visible: false,
                                            dataField: 'USERID',
                                            text: 'ID',
                                            hidden: true
                                          },
                                          {
                                            dataField: 'USERFNAME',
                                            text: 'First Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'USERLNAME',
                                            text: 'Last Name',
                                            sort: true,
                                            filter: textFilter()
                                          },
                                          {
                                            dataField: 'USERACTIONS',
                                            text: 'User Action',
                                            formatter: (cell, row, rowIndex) => (
                                              <div ml-auto>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                  <UserEntry
                                                    loadTable = {loadTable}
                                                    key = {row.USERID}
                                                    userid = {row.USERID}
                                                    user = {row}
                                                    operation="Edit"/>
                                                </div>
                                              </div>
                                            )
                                          }]);

  const loadTable = query => {
        const { match, location, companyid } = props;
        setCurrentURL(location.pathname);
        setLoading(true);

        setUserID(getUserID())
        console.log("Current (on users loading) User ID is " + getUserID())

        var getUrl = url;

        axios.get(getUrl+"&COMPANY_ID="+companyid)
        .then(response => {
          setRecords(response.data);
        })
        .catch(error => {
          setRecords([]);
          console.log('There has been a problem with your fetch operation: ', error.message);
          if (error.response.status === 401){
            removeUserSession();
            props.history.push('/login');
          }
        });
        setRecords([]);
        setLoading(false);
  }

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      setUserID(getUserID())
      console.log("Current User ID is " + getUserID())
      loadTable(querystr);
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      setUserID(getUserID())
      console.log("Current (remounted) User ID is " + getUserID())
      loadTable(querystr)
    }
  }, []);

  if (loading) {
    return <div className="content">Loading...</div>
  }

  const LoadingIndication = () => (
    <Spinner animation="border" />
  );
  const NoDataIndication = () => (
    <>There are no data to display.</>
  );

  const options = {

                        sizePerPageList: [ {
                          text: '10', value: 10
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: records.length
                        } ]

                      };

  return (
    <div className="container companyprofile-container">
      <UserEntry loadTable = {loadTable}  operation="New"/>
      <div className="row hdr">
        <div className="col-sm-12">
        </div>
      </div>
      <div  style={{ marginTop: 20 }}>
      <BootstrapTable striped hover
        keyField='USERID'
        columns={ columns }
        data={ records }
        pagination={ paginationFactory(options) }
        filter={ filterFactory() }
        noDataIndication={loading?() => <LoadingIndication />:() => <NoDataIndication />}>
      </BootstrapTable>
      </div>
    </div>
  )
}

export default withRouter(CompanyUsers);
